import styled from 'styled-components';
import { MarkdownViewer } from '../MarkdownViewer';

const StyledPrivacyPolicy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h1 strong {
    font-family: 'Parafina', system-ui, Helvetica, Arial, sans-serif;
  }
`;

/**
 * Displays all the privacy documents together.
 */
export const PrivacyPolicy = ({ className }: { className?: string }) => {
  const fileNames = ['tos.md', 'baa.md', 'aa.md'];

  return (
    <StyledPrivacyPolicy className={className ? className : ''}>
      {fileNames.map((fileName: string) => (
        <MarkdownViewer fileUrl={`/signup/documents/${fileName}`} key={`user-agreement-${fileName}`} />
      ))}
    </StyledPrivacyPolicy>
  );
};
