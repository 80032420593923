import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { AppealReviewsResponse, client } from '../api';
import PageContainer from '../components/PageContainer';
import AppealReviewsTable from '../components/appealReviews/AppealReviewsTable';
import { Outlet } from 'react-router-dom';
import { appealReviewsQueryKey } from '../queries';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';

const AppealReviewsTablePage: React.FC = () => {
  const { data, isLoading, error } = useQuery<AppealReviewsResponse>({
    queryKey: appealReviewsQueryKey(),
    queryFn: () => {
      return client.get(`appeal-reviews`).then((res) => res.data);
    },
  });

  let body;
  if (isLoading) {
    body = <LoadingSpinner />;
  } else if (error) {
    body = 'Error, please try again';
  } else if (data) {
    if (data.appeal_reviews.length === 0) {
      body = 'No appeal reviews found';
    } else {
      body = <AppealReviewsTable data={data.appeal_reviews} />;
    }
  }

  return (
    <>
      <PageContainer title="Reviews">{body}</PageContainer>
      <Outlet />
    </>
  );
};

export default AppealReviewsTablePage;
