import styled from 'styled-components';
import { Select, MenuItem } from '@mui/material';

export const DropdownItem = MenuItem;

export const Dropdown = styled(Select)<{ $hasError?: boolean }>`
  margin-top: -0.75rem;

  > fieldset {
    border-radius: var(--border-radius-medium);
    transition: border-color 0.2s ease-out;
  }

  // stops the empty legend from breaking the focus outline of the input field
  > fieldset > legend {
    width: 0px;
  }

  ${({ $hasError }) =>
    $hasError &&
    `
    > fieldset {
      border: 1px solid var(--red);
    }
  `}
`;
