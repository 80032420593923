import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnFiltersState,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

import type { TaskSummary } from '../../api';
import { sortOfficeNames } from '../../utils';
import { TableDropdownFilter } from '../TableDropdownFilter/TableDropdownFilter';

import { useNavigate, useParams } from 'react-router-dom';
import {
  ClickableRow,
  FilterBar,
  FilterBarRow,
  FilterGroup,
  FilterLabel,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  HeaderSortingIndicator,
  OfficeNameTableDropdownFilter,
} from '../Table';

type UITaskStatus = 'New' | 'Open' | 'Complete';

const columnHelper = createColumnHelper<TaskSummary>();

interface TaskTableProps {
  data: TaskSummary[];
}

const TasksTable: React.FC<TaskTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const { taskId } = useParams();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    { id: 'task_status', value: 'queued' },
  ]);

  const typeStrings: string[] = [];
  const prescriberStrings: string[] = [];
  const officeNameStrings: string[] = [];
  data.forEach((task) => {
    typeStrings.push(task.task_type);
    prescriberStrings.push(task.prescriber_name);
    if (task.office_name) {
      officeNameStrings.push(task.office_name);
    }
  });
  const sortedOfficeNameStrings = sortOfficeNames(officeNameStrings);

  const getUITaskStatus = (task: TaskSummary): UITaskStatus => {
    const { task_status, task_read_status } = task;
    if (task_status === 'queued' && task_read_status === 'unread') {
      return 'New';
    }
    if (task_status === 'queued') {
      return 'Open';
    }
    if (task_status === 'completed') {
      return 'Complete';
    }
    return 'Open';
  };

  const columns = [
    columnHelper.accessor('task_created_at', {
      header: 'Task Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('task_status', {
      header: 'Status',
      filterFn: 'equalsString',
      cell: (info) => {
        return getUITaskStatus(info.row.original);
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('task_type', {
      header: 'Type',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('patient_name', {
      header: 'Patient',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('prescriber_name', {
      header: 'Prescriber',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('drug_name', {
      header: 'Drug',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('prescription_recieved_at', {
      header: 'Rx Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('office_name', {
      header: 'Office',
      enableGlobalFilter: false,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    initialState: { columnVisibility: { task_status: false } },
  });

  return (
    <TableContainer>
      <FilterBar>
        <FilterBarRow>
          <FilterGroup>
            <FilterLabel
              $active={table.getColumn('task_status')!.getFilterValue() == 'queued'}
              onClick={() => table.getColumn('task_status')!.setFilterValue('queued')}
            >
              Open
            </FilterLabel>
            <FilterLabel
              $active={table.getColumn('task_status')!.getFilterValue() == 'completed'}
              onClick={() => table.getColumn('task_status')!.setFilterValue('completed')}
            >
              Complete
            </FilterLabel>
          </FilterGroup>

          <SearchInput
            label="Search by name"
            hideLabel={true}
            onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
          />
        </FilterBarRow>

        <FilterBarRow>
          <TableDropdownFilter
            optionStrings={typeStrings}
            label={'Type'}
            onChange={(tasktype: string) => {
              table.getColumn('task_type')!.setFilterValue(tasktype);
            }}
          />
          <TableDropdownFilter
            optionStrings={prescriberStrings}
            label={'Prescriber'}
            onChange={(prescriberName: string) => {
              table.getColumn('prescriber_name')!.setFilterValue(prescriberName);
            }}
          />
          <OfficeNameTableDropdownFilter
            optionStrings={sortedOfficeNameStrings}
            label={'Office Name'}
            onChange={(officeName: string) => {
              table.getColumn('office_name')!.setFilterValue(officeName);
            }}
          />
        </FilterBarRow>
      </FilterBar>

      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader key={header.id} onClick={() => header.column.toggleSorting()}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <HeaderSortingIndicator
                      isSorted={header.column.getIsSorted()}
                      sortDirection={header.column.getNextSortingOrder()}
                    />
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <ClickableRow
                $selected={row.original.id === taskId}
                key={row.original.id}
                onClick={() => navigate(row.original.id)}
                $new={getUITaskStatus(row.original) === 'New'}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </ClickableRow>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>No results.</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default TasksTable;
