import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { Toast } from 'design-system/src/components/Toast/Toast';
import chevronLeft from 'design-system/src/assets/icons/chevronLeft.svg';
import linkChain from 'design-system/src/assets/icons/linkChain.svg';
import layoutWindow from 'design-system/src/assets/icons/layoutWindow.svg';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { IconButton } from '../Button/Button';

const StyledItemViewTopNav = styled.div`
  // NOTE: the top nav was shifting in size when we were only using 'height'
  min-height: var(--item-view-top-nav-height);
  max-height: var(--item-view-top-nav-height);
  padding: 0 0.75rem;
  box-sizing: border-box;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackButton = styled.a`
  all: unset;
  cursor: pointer;
  display: flex;
  gap: 0.625rem;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem 0.25rem 0.35rem;
  border-radius: var(--border-radius-medium);
  transition: background-color 0.2s ease-out;

  > img {
    width: 0.625rem;
    height: 0.625rem;
    margin-bottom: 1px;
  }

  &:hover {
    background-color: var(--light-border-gray);
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export type ItemViewTopNavProps = {
  isSidebarCollapsed?: boolean;
  setIsSidebarCollapsed?: Dispatch<SetStateAction<boolean>>;
  // If the item has no sidebar to collapse
  noSidebar?: boolean;
};

/**
 * A bar that sits on top of the page. It's designed to work for a detailed view of an item in a table and contains a button for toggling visibility of the sidebar.
 */
export const ItemViewTopNav = ({ isSidebarCollapsed, setIsSidebarCollapsed, noSidebar }: ItemViewTopNavProps) => {
  return (
    <StyledItemViewTopNav>
      <BackButton onClick={() => history.back()}>
        <img src={chevronLeft} alt="" />{' '}
        <Typography renderedAs="span" styledAs="smallBody">
          Back
        </Typography>
      </BackButton>

      <RightSide>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(window.location.href).then(
              () => {
                toast.custom(() => <Toast variant="success">Link copied</Toast>);
              },
              () => {
                toast.custom(() => <Toast variant="error">An error occurred</Toast>);
              },
            );
          }}
        >
          <img src={linkChain} alt="copy link to current page" />
        </IconButton>

        {!noSidebar && isSidebarCollapsed !== undefined && setIsSidebarCollapsed && (
          <IconButton onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
            <img src={layoutWindow} alt={isSidebarCollapsed ? 'expand sidebar' : 'collapse sidebar'} />
          </IconButton>
        )}
      </RightSide>
    </StyledItemViewTopNav>
  );
};
