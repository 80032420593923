import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { client } from '../api';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import PageContainer from '../components/PageContainer';
import NotificationSettings from '../components/notifications/NotificationSettings';
import { FilterBarRow, FilterGroup, FilterLabel, Table, TableCell, TableHeader } from '../components/Table';

type Tab = 'config' | 'notifications';

type ConfigData = {
  show_config: boolean;
  icd_mapping: Record<string, string>;
};

const SettingsTabs = styled(FilterBarRow)`
  margin: 1rem 0;
`;

const HelperText = styled(Typography)`
  padding-left: 0.5rem;
`;

// TODO(design-system): Replace with Link component
const Link = styled.a`
  color: var(--dark-gray);
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid var(--medium-gray);
  padding-bottom: 2px;
`;

const ConfigContainer = styled.div`
  max-width: 40em;
`;

const ConfigView: React.FC<{ data: ConfigData }> = ({ data }) => {
  const { icd_mapping } = data;
  return (
    <ConfigContainer>
      <HelperText styledAs="body" marginBottom="1rem">
        Below are your account settings for default ICD-10 codes to use on prior authorization requests for specific
        medications, unless otherwise specified in clinical documentation.
        <br />
        <br />
        You can <Link href="mailto:support@withtandem.com">contact support</Link> at any time to update these settings.
      </HelperText>
      <Table>
        <thead>
          <tr>
            <TableHeader>Drug Name</TableHeader>
            <TableHeader>ICD-10 Code</TableHeader>
          </tr>
        </thead>
        <tbody>
          {Object.entries(icd_mapping).map(([ingredient, code]) => (
            <tr key={ingredient}>
              <TableCell>{ingredient}</TableCell>
              <TableCell>{code}</TableCell>
            </tr>
          ))}
        </tbody>
      </Table>
    </ConfigContainer>
  );
};

const SettingsPage: React.FC = () => {
  const [tab, setTab] = useState<Tab>('notifications');
  const configQuery = useQuery<ConfigData>({
    queryKey: ['config'],
    queryFn: () => {
      return client.get(`config`).then((res) => res.data);
    },
  });
  if (configQuery.isLoading) {
    return <LoadingSpinner />;
  }
  let showConfig = false;
  if (configQuery.data) {
    showConfig = configQuery.data.show_config;
  }

  return (
    <PageContainer title="Settings">
      {showConfig ? (
        <SettingsTabs>
          <FilterGroup>
            <FilterLabel $active={tab === 'notifications'} onClick={() => setTab('notifications')}>
              Notifications
            </FilterLabel>
            <FilterLabel $active={tab === 'config'} onClick={() => setTab('config')}>
              Other Configurations
            </FilterLabel>
          </FilterGroup>
        </SettingsTabs>
      ) : null}
      {/* showConfig can only be true when configQuery.data is defined */}
      {tab === 'config' && showConfig && <ConfigView data={configQuery.data!} />}
      {tab === 'notifications' && <NotificationSettings />}
    </PageContainer>
  );
};

export default SettingsPage;
