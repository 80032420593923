import { useEffect } from 'react';
import styled from 'styled-components';
import { Controller, useForm, useWatch } from 'react-hook-form';
import type { AllFormData, MoreInfoFormData } from '../../../types.ts';
import { FormLabel } from '../../FormLabel';
import { Input } from '../../Input';
import { Dropdown } from '../../Dropdown.tsx';
import { FormContainer } from '../FormContainer/FormContainer';
import { requiredErrorMessage, getSpecialtyOptions, getSizeOptions, getEhrOptions } from '../utils.tsx';
import { ControllerWrapper } from '../ControllerWrapper.tsx';
import { SubmitButton } from '../SubmitButton/SubmitButton';

const StyledSignupForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export type MoreInfoFormProps = {
  onBack: () => void;
  onContinue: (formData: MoreInfoFormData) => void;
  onBeforeUnload: (formData: MoreInfoFormData) => void;
  // For prefilling the form with data if the user navigates to another step and then comes back
  formData: AllFormData;
};

/**
 * Form collecting less basic but still useful information for a provider to sign up.
 */
export const MoreInfoForm = ({ onBack, onContinue, onBeforeUnload, formData }: MoreInfoFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<MoreInfoFormData>({ defaultValues: formData });
  const [specialtyValue, ehrValue] = useWatch({ control, name: ['specialty', 'ehr'] });

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    onBeforeUnload(getValues());
    // We need to return a truthy value to get the browser to show the alert
    // that tries to persuade the user to stay and fill out the form
    return true;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <FormContainer title="Tell us about your practice" showExtraContent={true} onBack={onBack}>
      <StyledSignupForm onSubmit={handleSubmit(onContinue)}>
        <ControllerWrapper>
          <Controller
            control={control}
            name="practice_name"
            rules={{ required: true }}
            render={({ field }) => <Input label="Practice name" hasError={!!errors.practice_name} {...field} />}
          />
          {errors.practice_name && requiredErrorMessage}
        </ControllerWrapper>

        <ControllerWrapper>
          <Controller
            name="specialty"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel id="specialty-label">Specialty</FormLabel>
                <Dropdown labelId="specialty-label" id="specialty" label="Specialty" {...field}>
                  {getSpecialtyOptions()}
                </Dropdown>
              </>
            )}
          />
        </ControllerWrapper>

        {specialtyValue && specialtyValue.includes('other') && (
          <Controller
            control={control}
            name="specify_other_specialty"
            rules={{ required: false }}
            render={({ field }) => <Input label="Specify other specialty" {...field} />}
          />
        )}

        <Controller
          name="size"
          control={control}
          render={({ field }) => (
            <>
              <FormLabel id="size-label">Size</FormLabel>
              <Dropdown labelId="size-label" id="size" label="Size" {...field}>
                {getSizeOptions()}
              </Dropdown>
            </>
          )}
        />

        <Controller
          name="ehr"
          control={control}
          render={({ field }) => (
            <>
              <FormLabel id="ehr-label">EHR</FormLabel>
              <Dropdown labelId="ehr-label" id="ehr" label="EHR" {...field}>
                {getEhrOptions()}
              </Dropdown>
            </>
          )}
        />

        {ehrValue === 'other' && (
          <Controller
            control={control}
            name="specify_other_ehr"
            render={({ field }) => <Input label="Specify other EHR" {...field} />}
          />
        )}

        <SubmitButton type="submit">Continue</SubmitButton>
      </StyledSignupForm>
    </FormContainer>
  );
};
