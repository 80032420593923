import styled from 'styled-components';

type Spacing = 'normal' | 'tight' | 'loose' | 'none';

export const FlexRow = styled.div<{ $centerAlign?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  ${({ $centerAlign = false }) => $centerAlign && 'align-items: center'};
`;

export const FlexColumn = styled.div<{ $spacing?: Spacing; $centerAlign?: boolean; $fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $centerAlign }) => ($centerAlign ? 'center' : 'flex-start')};
  gap: ${({ $spacing }) => getSpacing($spacing)};
  ${({ $fullWidth }) => $fullWidth && 'flex-grow: 1;'}
`;

function getSpacing(spacing?: Spacing) {
  switch (spacing) {
    case 'tight':
      return '0.5rem';
    case 'loose':
      return '1.6rem';
    case 'none':
      return '0';
    case 'normal':
    default:
      return '0.8rem';
  }
}
