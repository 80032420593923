import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { client } from '../../api';
import type { PriorAuthReviewFormData, PriorAuthReviewFormUpdateData } from '../../api';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
} from '../tableItemDetailViewComponents/Containers';
import React, { useContext, useState } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import styled from 'styled-components';
import { PriorAuthReviewContext } from './contexts';
import { Button } from '../Button/Button';
import { TimelineItemList, TimelineItem } from '../TimelineItem/TimelineItem';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import toast from 'react-hot-toast';
import { Toast } from 'design-system/src/components/Toast/Toast';
import { CustomPaForm } from './CustomPaForm/CustomPaForm';

const FormAndFooterContainer = styled.div`
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-gray);
  background: var(--white);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  margin: 0 0.25rem;
`;

const StyledCustomPaFormViewer = styled.div`
  // TODO: programmatically set this height
  // 218 is sum of
  // - StyledItemViewTopNav height
  // - ContentOuterWrapper padding
  // - title typography height
  // - FormAndResultContainer margin (top and bottom)
  // - FormControlsContainer height
  height: calc(100vh - 218px);
  overflow-y: scroll;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid var(--light-border-gray);
  background: var(--white);
  border-bottom-left-radius: var(--border-radius-medium);
  border-bottom-right-radius: var(--border-radius-medium);
  position: relative;
`;

const FooterLoadingSpinner = styled(LoadingSpinner)`
  width: 1.5rem;
  // Horizontally center the spinner regardless of the other
  // buttons in the footer
  position: absolute;
  left: calc(50% - 0.75rem);
`;

const AcceptButton = styled(Button)`
  display: flex;
  height: 2rem;
  padding: 0px 0.75rem;
  align-items: center;
  border-radius: var(--border-radius-medium);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.15px;
  gap: 0.25rem;
  transition: background-color 0.2s ease-out;

  &:hover {
    // There's no design system color close to this
    background-color: #532e89;
    border-color: #532e89;
  }
`;

const ResetFormButton = styled(AcceptButton)`
  background-color: transparent;
  color: var(--dark-gray);
  border: 1px solid transparent;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: var(--light-border-gray);
    border-color: var(--light-border-gray);
  }
`;

const PriorAuthReviewView: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [attestationCheckboxIsChecked, setAttestationCheckboxIsChecked] = useState(false);
  const [showAttestationCheckboxError, setShowAttestationCheckboxError] = useState(false);

  const attestationCheckboxErrorMessageId = 'prior-auth-form-attestation-checkbox-error-message';
  const formId = 'custom-pa-form-viewer';

  const queryClient = useQueryClient();

  const { priorAuthReviewQuery, priorAuthReviewId, onPriorAuthReviewUpdate, updating, setUpdating } =
    useContext(PriorAuthReviewContext);

  const formQuery = useQuery<PriorAuthReviewFormData>({
    queryKey: ['customPaFormViewer', priorAuthReviewId],
    queryFn: async () => {
      return client.get(`prior-auth-review/${priorAuthReviewId}/form`).then((res) => res.data);
    },
  });

  const saveQuestionAnswer = useMutation<PriorAuthReviewFormData, AxiosError, PriorAuthReviewFormUpdateData>({
    mutationFn: async (data) => {
      const res = await client.post(`prior-auth-review/${priorAuthReviewId}/form/answer`, data);
      return res.data;
    },
    onSuccess: () => {
      toast.custom(() => <Toast variant="success">Form saved</Toast>);
      return queryClient.invalidateQueries({ queryKey: ['customPaFormViewer', priorAuthReviewId] });
    },
    onError: () => {
      toast.custom(() => <Toast variant="error">An error occurred</Toast>);
    },
  });

  const resetPriorAuthReviewForm = useMutation({
    mutationFn: async () => {
      const res = await client.post(`prior-auth-reviews/${priorAuthReviewId}/form/reset`);
      return res.data;
    },
    onSuccess: () => {
      toast.custom(() => <Toast variant="success">Form cleared</Toast>);
      return queryClient.invalidateQueries({ queryKey: ['customPaFormViewer', priorAuthReviewId] });
    },
    onError: () => {
      toast.custom(() => <Toast variant="error">An error occurred</Toast>);
    },
  });

  const navigate = useNavigate();
  const acceptPriorAuthReview = useMutation({
    mutationFn: async () => {
      const res = await client.post(`prior-auth-reviews/${priorAuthReviewId}/form/submit`, {
        attestation_submitted: attestationCheckboxIsChecked,
      });
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onPriorAuthReviewUpdate();
      navigate('/prior-auth-reviews');
      return queryClient.invalidateQueries({ queryKey: ['customPaFormViewer', priorAuthReviewId] });
    },
    onError: () => {
      toast.custom(() => <Toast variant="error">An error occurred</Toast>);
      setUpdating(false);
    },
  });

  let body;
  if (priorAuthReviewQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (priorAuthReviewQuery.error) {
    body = 'Error, please try again';
  } else if (priorAuthReviewQuery.data) {
    const priorAuthReviewInfo = priorAuthReviewQuery.data.prior_auth_review;
    const priorAuthReviewTitle = `${priorAuthReviewQuery.data.drug.name} (${priorAuthReviewQuery.data.patient.last_name})`;

    let formBody;
    if (formQuery.isLoading) {
      formBody = <LoadingSpinner />;
    }
    if (formQuery.error) {
      formBody = 'Error, please try again';
    }
    if (formQuery.data) {
      formBody = (
        <CustomPaForm
          form={formQuery.data.form}
          formStatus={formQuery.data.form_status}
          formId={formId}
          saveQuestionAnswer={saveQuestionAnswer.mutate}
          attestationCheckboxIsChecked={attestationCheckboxIsChecked}
          setAttestationCheckboxIsChecked={setAttestationCheckboxIsChecked}
          showAttestationCheckboxError={showAttestationCheckboxError}
          setShowAttestationCheckboxError={setShowAttestationCheckboxError}
          attestationCheckboxErrorMessageId={attestationCheckboxErrorMessageId}
        />
      );
    }

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed} />

          <ContentOuterWrapper>
            <CenteredContent>
              {/* TODO: truncate this */}
              <Typography styledAs="title" renderedAs="h2" marginBottom="1rem">
                {priorAuthReviewTitle}
              </Typography>

              {/* This check is just to satisfy typescript. If the prior authorization
                  review has been reviewed, there should be reviewed_by and reviewed_at
                  properties */}
              {priorAuthReviewInfo.status === 'submitted' &&
                priorAuthReviewInfo.reviewed_by &&
                priorAuthReviewInfo.reviewed_at && (
                  <TimelineItemList>
                    <TimelineItem
                      key={'prior-authorization-review-acceptance'}
                      // This timeline always only has 1 item in it
                      isLastCommentInList={true}
                      author={priorAuthReviewInfo.reviewed_by}
                      timestamp={priorAuthReviewInfo.reviewed_at}
                      action="accepted prior authorization"
                    />
                  </TimelineItemList>
                )}

              <FormAndFooterContainer>
                <StyledCustomPaFormViewer id={formId}>{formBody}</StyledCustomPaFormViewer>

                {priorAuthReviewInfo.status !== 'submitted' && (
                  <FormFooter>
                    {/* This div keeps the Accept button pushed to the right */}
                    <div>
                      <ResetFormButton
                        onClick={() => {
                          if (!updating && !resetPriorAuthReviewForm.isPending) {
                            resetPriorAuthReviewForm.mutate();
                          }
                        }}
                        isLoading={resetPriorAuthReviewForm.isPending}
                        hasLightBackground={true}
                      >
                        Reset original answers
                      </ResetFormButton>
                    </div>

                    {saveQuestionAnswer.isPending && <FooterLoadingSpinner />}

                    {formQuery.data?.form_status === 'FORM_STATUS_COMPLETED' && (
                      <AcceptButton
                        onClick={() => {
                          if (updating || resetPriorAuthReviewForm.isPending) {
                            return;
                          }
                          if (attestationCheckboxIsChecked) {
                            acceptPriorAuthReview.mutate();
                          } else {
                            setShowAttestationCheckboxError(true);
                            // Scroll to the bottom of the form so the user can see the checkbox and error
                            const formContainer = document.getElementById(formId);
                            if (formContainer) {
                              formContainer.scrollTop = formContainer.scrollHeight;
                            }
                          }
                        }}
                        isLoading={updating}
                        aria-describedby={attestationCheckboxErrorMessageId}
                      >
                        Accept
                      </AcceptButton>
                    )}
                  </FormFooter>
                )}
              </FormAndFooterContainer>
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>
        <Sidebar $isCollapsed={isSidebarCollapsed}>
          <SidebarTopBar />
          <DrugPanel drug={priorAuthReviewQuery.data.drug} />
          <PatientPanel patient={priorAuthReviewQuery.data.patient} />
          <PrescriberPanel prescriber={priorAuthReviewQuery.data.prescriber} />
        </Sidebar>
      </BodyContainer>
    );
  }

  return body;
};

export default PriorAuthReviewView;
