import styled from 'styled-components';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0rem;
  height: 100%;
`;

export const TopNavAndContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ContentOuterWrapper = styled.div`
  padding: 1.25rem;
  // This allows the pdf iframe to expand and fill the page
  height: 100%;
`;

export const CenteredContent = styled.div`
  max-width: 40rem;
  margin: 0 auto;
`;

export const Hairline = styled.div`
  margin: 1.5rem 0;
  height: 1px;
  width: 100%;
  background-color: var(--light-border-gray);
`;
