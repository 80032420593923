import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { Typography } from '../Typography/Typography';

type StyleProps = {
  $inDOM: boolean;
  $animatedIn: boolean;
  $marginTop: string;
};

const animationDuration = 200;

const StyledErrorMessage = styled(Typography)<StyleProps>`
  color: var(--red);

  // We're using CSS grid in order to smoothly animate the height of the container
  // Other methods for animating height require knowing the approximate height of
  // the contents or leave empty space where the element would be rendered
  display: ${({ $inDOM }) => ($inDOM ? 'grid' : 'none')};
  grid-template-rows: ${({ $animatedIn }) => ($animatedIn ? '1fr' : '0fr')};

  margin-top: ${({ $animatedIn, $marginTop }) => ($animatedIn ? $marginTop : 0)};

  transition:
    grid-template-rows ${animationDuration}ms ease-out,
    opacity ${animationDuration}ms ease-out,
    margin-top ${animationDuration}ms ease-out;

  // This is necessary for the height to shrink when the element is hidden
  > div {
    overflow: hidden;
  }
`;

export interface ErrorMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  // `true` by default
  visible?: boolean;
  // The space between the margin and the button/input/etc above it
  // This is animated in with the height of the error message
  // so it's revealed smoothly and there's no uneccessary whitespace
  // when the error message is hidden
  marginTop?: string;
}

/**
 * A component for displaying error messages.
 *
 * The message is colored red and will animate in when mounted. If you set the `visible` prop to `false` remove the error message, it will also animate out.
 */
export const ErrorMessage = ({ children, visible = true, marginTop = '0.25rem', ...rest }: ErrorMessageProps) => {
  // We have prop and state visible variables to account for animation.
  // This is because we cannot animate from a `display: block` state,
  // in which an element is not in the DOM, to a different `display` state.
  // When the `visible` prop is set to `true`, we place the element in the DOM
  // and *then* animate it in. When the `visible` prop is set to `false`, we animate
  // the element out and *then* remove it from the DOM.
  const [inDOM, setInDOM] = useState(false);
  const [animatedIn, setAnimatedIn] = useState(false);

  useEffect(() => {
    if (visible && !inDOM) {
      setInDOM(true);
      // Quick pause so the element is in the DOM before triggering the animation
      setTimeout(() => {
        setAnimatedIn(true);
      }, 10);
    }
    if (!visible && animatedIn) {
      setAnimatedIn(false);
      setTimeout(() => {
        setInDOM(false);
      }, animationDuration);
    }
  }, [visible]);

  return (
    <StyledErrorMessage
      weight={500}
      $inDOM={inDOM}
      $animatedIn={animatedIn}
      $marginTop={marginTop}
      aria-live="polite"
      // This needs to be a div because of the child div below
      renderedAs="div"
      {...rest}
    >
      {/* This is necessary for the height to shrink when the element is hidden */}
      <div>{children}</div>
    </StyledErrorMessage>
  );
};
