import React from 'react';
import { useQuery } from '@tanstack/react-query';
import PageContainer from '../components/PageContainer';
import RxTable from '../components/rx/RxTable';
import { client, Drug } from '../api';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { formatStandardDate } from '../utils';

const RxTablePage: React.FC = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['prescriptions'],
    queryFn: () =>
      client.get('/rxs').then((res) => {
        return res.data.map((item: Drug) => ({
          ...item,
          // We format dates here instead of in RxTable so that the global search function applies to the formatted data
          prescription_recieved_at: new Date(item.prescription_recieved_at).toLocaleDateString(),
          dob: formatStandardDate(item.dob),
        }));
      }),
  });

  let body;
  if (isLoading) {
    body = <LoadingSpinner />;
  } else if (error) {
    body = 'Error, please try again';
  } else if (data) {
    body = <RxTable data={data} />;
  }

  return <PageContainer title="Prescriptions">{body}</PageContainer>;
};

export default RxTablePage;
