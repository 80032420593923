import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { PriorAuthTableResponseRow, PriorAuthTableRow, client } from '../api';
import PageContainer from '../components/PageContainer';
import { Outlet } from 'react-router-dom';
import { tasksQueryKey } from '../queries';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';

import PriorAuthTable from '../components/pas/PriorAuthTable';
import { formatStandardDate } from '../utils';
const PriorAuthTablePage: React.FC = () => {
  const { data, isLoading, error } = useQuery<PriorAuthTableRow[]>({
    queryKey: tasksQueryKey(),
    queryFn: () => {
      return client.get(`expiring-prior-auths`).then((res) => {
        return res.data.map((item: PriorAuthTableResponseRow) => ({
          ...item,
          formatted_approval_until: formatStandardDate(item.pa.approval_until),
          formatted_prescription_recieved_at: formatStandardDate(item.rx.prescription_recieved_at),
          formatted_dob: formatStandardDate(item.rx.dob),
        }));
      });
    },
  });

  let body;
  if (isLoading) {
    body = <LoadingSpinner />;
  } else if (error) {
    body = 'Error, please try again';
  } else if (data) {
    if (data.length === 0) {
      body = 'No results.';
    } else {
      body = <PriorAuthTable data={data} />;
    }
  }

  return (
    <>
      <PageContainer title="Expiring Prior Authorizations">{body}</PageContainer>
      <Outlet />
    </>
  );
};

export default PriorAuthTablePage;
