import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Content, Overlay } from '../Slideout';
import { PriorAuthReviewContext } from './contexts';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PriorAuthReviewResponse, client } from '../../api';
import { priorAuthReviewQueryKey, priorAuthReviewsQueryKey } from '../../queries';
import PriorAuthReviewView from './PriorAuthReviewView';

const PriorAuthReviewOverlay: React.FC = () => {
  const params = useParams();
  const priorAuthReviewId = params.priorAuthReviewId!;

  const queryClient = useQueryClient();
  const priorAuthReviewQuery = useQuery<PriorAuthReviewResponse>({
    queryKey: priorAuthReviewQueryKey(priorAuthReviewId),
    queryFn: async () => {
      const res = await client.get(`prior-auth-reviews/${priorAuthReviewId}`);
      return res.data;
    },
  });

  const [updating, setUpdating] = useState(false);

  return (
    <Overlay>
      <Content>
        <PriorAuthReviewContext.Provider
          value={{
            priorAuthReviewId,
            priorAuthReviewQuery,
            updating,
            setUpdating,
            refetchPriorAuthReview: async () =>
              queryClient.invalidateQueries({
                queryKey: priorAuthReviewQueryKey(priorAuthReviewId),
                exact: true,
              }),
            onPriorAuthReviewUpdate: async () => {
              queryClient.invalidateQueries({
                queryKey: priorAuthReviewsQueryKey(),
              });
              queryClient.invalidateQueries({
                queryKey: priorAuthReviewQueryKey(priorAuthReviewId),
              });
              setUpdating(false);
            },
          }}
        >
          <PriorAuthReviewView />
        </PriorAuthReviewContext.Provider>
      </Content>
    </Overlay>
  );
};

export default PriorAuthReviewOverlay;
