import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnFiltersState,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

import { useNavigate } from 'react-router-dom';
import {
  ClickableRow,
  FilterBar,
  FilterBarRow,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  HeaderSortingIndicator,
  OfficeNameTableDropdownFilter,
} from '../Table';
import moment from 'moment';
import { sortOfficeNames } from '../../utils';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { PriorAuthTableRow } from '../../api';
const columnHelper = createColumnHelper<PriorAuthTableRow>();

interface PriorAuthTableProps {
  data: PriorAuthTableRow[];
}

const PriorAuthTable: React.FC<PriorAuthTableProps> = ({ data }) => {
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([{ id: 'office_name', value: '' }]);

  const officeNameStrings: string[] = [];
  data.forEach((item) => {
    if (item.office_name) {
      officeNameStrings.push(item.office_name);
    }
  });
  const sortedOfficeNameStrings = sortOfficeNames(officeNameStrings);

  const columns = [
    columnHelper.accessor('formatted_prescription_recieved_at', {
      header: 'Prescribed on',
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('drug_name', {
      header: 'Drug',
    }),
    columnHelper.accessor('rx.prescriber_name', {
      header: 'Prescriber',
    }),
    columnHelper.accessor('rx.patient_name', {
      header: 'Patient',
    }),
    columnHelper.accessor('formatted_dob', {
      header: 'DOB',
    }),
    columnHelper.accessor('office_name', {
      header: 'Office',
      enableGlobalFilter: false,
      filterFn: (row, columnId, filterValue) => {
        if (!filterValue) return true;
        return row.getValue(columnId) === filterValue;
      },
    }),
    columnHelper.accessor('formatted_approval_until', {
      header: 'PA expires on',
      enableGlobalFilter: false,
      cell: (info) => (info.getValue() ? moment(info.getValue()).format('MM/DD/YYYY') : ''),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    initialState: { columnVisibility: { office_name: false } },
  });

  return (
    <TableContainer>
      <Typography styledAs="smallBody" weight={500}>
        Prior authorizations expiring in the next 30 days.
      </Typography>
      <FilterBar>
        <FilterBarRow>
          <SearchInput
            label="Search by drug or patient information"
            hideLabel={true}
            onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
            placement="left"
            width="20rem"
          />
          <OfficeNameTableDropdownFilter
            optionStrings={sortedOfficeNameStrings}
            label={'Office Name'}
            onChange={(officeName: string) => {
              table.getColumn('office_name')!.setFilterValue(officeName);
            }}
          />
        </FilterBarRow>
      </FilterBar>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader
                    id={header.id}
                    key={header.id}
                    onClick={() => header.column.toggleSorting()}
                    style={{ width: '150px' }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <HeaderSortingIndicator
                      isSorted={header.column.getIsSorted()}
                      sortDirection={header.column.getNextSortingOrder()}
                    />
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <ClickableRow key={row.id} onClick={() => navigate(`/rxs/${row.original.rx.id}`)}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </ClickableRow>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>No results.</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default PriorAuthTable;
