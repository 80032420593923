import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';
import React from 'react';
import chevronLeft from 'design-system/src/assets/icons/chevronLeft.svg';

const OuterWrapper = styled.div`
  display: flex;
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius-large);
  overflow: hidden;
  flex-direction: row;
  height: fit-content;
  width: fit-content;

  --form-width-desktop: 57rem;

  // TODO: when updating extra content, change 768 here to 1025
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const FormHalf = styled.div<{ $size: 'small' | 'large'; $mobileScreenWidth: number }>`
  box-sizing: border-box;
  padding: 2rem;
  background: var(--white);
  width: ${({ $size }) => ($size === 'small' ? 'calc(var(--form-width-desktop) / 2)' : 'var(--form-width-desktop)')};

  @media screen and (max-width: ${({ $mobileScreenWidth }) => $mobileScreenWidth}px) {
    width: 100%;
  }
`;

// const ExtraContent = styled.div`
//   box-sizing: border-box;
//   width: calc(var(--form-width-desktop) / 2);
//   // NOTE: We're discussing with design whether we can use --surface here
//   background-color: #faf5f2;
//   padding: 6.5rem 3rem;
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;

//   @media screen and (max-width: 1025px) {
//     width: 100%;
//   }
// `;

const ChildrenContainer = styled.div`
  margin-top: 1.25rem;
`;

const StyledBackButton = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

const Subtitle = styled(Typography)`
  color: var(--dark-gray);
  margin-top: 0.5rem;
`;

const BackButtonIcon = styled.img`
  padding: 2px;
  box-sizing: border-box;
  height: 1.25rem;
  width: 1.25rem;
`;

export type FormContainerProps = {
  children: React.ReactNode;
  // The title at the top of the form
  title?: string;
  subtitle?: string | React.ReactNode;
  // Whether to show the marketing content to the right of the form
  showExtraContent?: boolean;
  // If provided, a back button will be shown at the top of the form
  onBack?: () => void;
};

/**
 * A container with the shared styles for forms in the signup flow.
 */
export const FormContainer = ({ children, title, subtitle, showExtraContent, onBack }: FormContainerProps) => {
  return (
    <OuterWrapper>
      <FormHalf $size={showExtraContent ? 'small' : 'large'} $mobileScreenWidth={showExtraContent ? 768 : 1025}>
        {onBack && (
          <StyledBackButton onClick={onBack}>
            <BackButtonIcon src={chevronLeft} alt="" />
            <Typography styledAs="body" renderedAs="span" weight={500}>
              Back
            </Typography>
          </StyledBackButton>
        )}

        {title && (
          <Typography styledAs="h5" renderedAs="h1">
            {title}
          </Typography>
        )}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}

        <ChildrenContainer>{children}</ChildrenContainer>
      </FormHalf>

      {/* TODO: Uncomment and update the extra content section when the marketing materials are ready.
                Also uncomment ExtraContent above and update OuterWrapper per the comment's instructions */}
      {/* {showExtraContent && (
        <ExtraContent>
          <Typography styledAs="largeBody" weight={500}>
            Streamline medication access for your practice and patients.
          </Typography>
          <Typography styledAs="largeBody" weight={500}>
            Tell us more about your practice and we will contact you about getting started!
          </Typography>
        </ExtraContent>
      )} */}
    </OuterWrapper>
  );
};
