import React, { useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import unchecked from 'design-system/src/assets/icons/unchecked.svg';
import checked from 'design-system/src/assets/icons/checked.svg';
import { Typography } from 'design-system/src/components/Typography/Typography';
import toast from 'react-hot-toast';
import { Toast } from 'design-system/src/components/Toast/Toast';
import { client } from '../../api';
import { priorAuthReviewsQueryKey } from '../../queries';
import { PriorAuthSubmitModal } from './PriorAuthSubmitModal';

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnFiltersState,
  VisibilityState,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

import type { Row } from '@tanstack/react-table';

import type { PriorAuthReviewSummary, PriorAuthReviewStatus } from '../../api';
import { TableDropdownFilter } from '../TableDropdownFilter/TableDropdownFilter';

import { useNavigate, useParams } from 'react-router-dom';
import {
  ClickableRow,
  FilterBar,
  FilterBarRow,
  FilterGroup,
  FilterLabel,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  HeaderSortingIndicator,
  BulkActionButton,
  ToggleSelectionCheckbox,
} from '../Table';

type UITaskStatus = 'Open' | 'Reviewed';

const columnHelper = createColumnHelper<PriorAuthReviewSummary>();

interface PriorAuthReviewTableProps {
  data: PriorAuthReviewSummary[];
}

const PriorAuthReviewsTable: React.FC<PriorAuthReviewTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const { priorAuthReviewId } = useParams();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([{ id: 'status', value: 'Open' }]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    // The status column is always hidden
    status: false,
    // The selection column is originally visible but becomes hidden when the status
    // filter is toggled (because you can't select accepted reviews)
    selection: true,
  });
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);

  const getUIPriorAuthReviewStatus = (status: PriorAuthReviewStatus): UITaskStatus => {
    return status === 'submitted' ? 'Reviewed' : 'Open';
  };

  const prescriberStrings: string[] = [];
  const officeNameStrings: string[] = [];
  data.forEach((priorAuthReview) => {
    prescriberStrings.push(priorAuthReview.prescriber_name);
    officeNameStrings.push(priorAuthReview.office_name);
  });

  const queryClient = useQueryClient();
  const acceptPriorAuthReview = useMutation<
    {},
    AxiosError<{ error: string }>,
    { attestationCheckboxIsChecked: boolean }
  >({
    mutationFn: async (attestationCheckboxIsChecked) => {
      const res = await client.post(`prior-auth-reviews/bulk/form/submit`, {
        prior_auth_review_ids: selectedRowIds,
        attestation_submitted: attestationCheckboxIsChecked,
      });
      return res.data;
    },
    onSuccess: () => {
      toast.custom(() => (
        <Toast variant="success">{selectedRowIds.length === 1 ? 'Prior auth' : 'Prior auths'} accepted</Toast>
      ));
      setSelectedRowIds([]);
      setAllRowsSelected(false);
      return queryClient.invalidateQueries({ queryKey: priorAuthReviewsQueryKey() });
    },
    onError: (error) => {
      // TODO: add "An error occurred" to a shared constants file
      const errorMessage = error.response?.data.error ? error.response?.data.error : 'An error occurred';
      toast.custom(() => <Toast variant="error">{errorMessage}</Toast>);
      // Invalidate the query on error in case some of the prior auths submitted did succeed
      return queryClient.invalidateQueries({ queryKey: priorAuthReviewsQueryKey() });
    },
  });

  const ToggleAllRowsSelectionButton = (
    <ToggleSelectionCheckbox
      onClick={() => {
        const rows = table.getRowModel().flatRows;
        if (selectedRowIds.length !== rows.length) {
          const rowIds = rows.map((row) => row.original.id);
          setSelectedRowIds(rowIds);
          setAllRowsSelected(true);
        } else {
          setSelectedRowIds([]);
          if (allRowsSelected) {
            setAllRowsSelected(false);
          }
        }
      }}
    >
      <img
        src={allRowsSelected ? checked : unchecked}
        alt={allRowsSelected ? 'all rows selected' : 'select all rows'}
      />
    </ToggleSelectionCheckbox>
  );

  const getToggleRowSelectionButton = (row: Row<PriorAuthReviewSummary>) => {
    const isChecked = selectedRowIds.includes(row.original.id);

    return (
      <ToggleSelectionCheckbox
        onClick={() => {
          const indexOfRowInSelectedRows = selectedRowIds.indexOf(row.original.id);
          if (indexOfRowInSelectedRows !== -1) {
            const updatedSelectedRowIds = [...selectedRowIds];
            updatedSelectedRowIds.splice(indexOfRowInSelectedRows, 1);
            setSelectedRowIds(updatedSelectedRowIds);
            if (allRowsSelected) {
              setAllRowsSelected(false);
            }
          } else {
            const updatedSelectedRowIds = [...selectedRowIds, row.original.id];
            setSelectedRowIds(updatedSelectedRowIds);
            const rows = table.getRowModel().flatRows;
            if (updatedSelectedRowIds.length === rows.length) {
              setAllRowsSelected(true);
            }
          }
        }}
      >
        <img src={isChecked ? checked : unchecked} alt={isChecked ? 'selected' : 'unselected'} />
      </ToggleSelectionCheckbox>
    );
  };

  const columns = [
    columnHelper.display({
      // @ts-ignore this is a special column with a special button header
      header: ToggleAllRowsSelectionButton,
      id: 'selection',
      cell: (info: any) => {
        return getToggleRowSelectionButton(info.row);
      },
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('created_at', {
      header: 'Date Created',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('patient_name', {
      header: 'Patient',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('prescriber_name', {
      header: 'Prescriber',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor((row) => getUIPriorAuthReviewStatus(row.status), {
      id: 'status',
      header: 'Status',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('drug_name', {
      header: 'Drug',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('prescription_recieved_at', {
      header: 'Prescription Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('office_name', {
      header: 'Office',
      enableGlobalFilter: false,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    onColumnVisibilityChange: setColumnVisibility,
  });

  return (
    <>
      <TableContainer>
        <FilterBar>
          <FilterBarRow>
            <FilterGroup>
              <FilterLabel
                $active={table.getColumn('status')!.getFilterValue() == 'Open'}
                onClick={() => {
                  table.getColumn('status')!.setFilterValue('Open');
                  setColumnVisibility((prev) => ({
                    ...prev,
                    selection: true,
                  }));
                }}
              >
                Open
              </FilterLabel>
              <FilterLabel
                $active={table.getColumn('status')!.getFilterValue() == 'Reviewed'}
                onClick={() => {
                  table.getColumn('status')!.setFilterValue('Reviewed');
                  setColumnVisibility((prev) => ({
                    ...prev,
                    selection: false,
                  }));
                }}
              >
                Reviewed
              </FilterLabel>
            </FilterGroup>
            <SearchInput
              label="Search by name"
              hideLabel={true}
              onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
            />
          </FilterBarRow>

          {!selectedRowIds.length && (
            <FilterBarRow>
              <TableDropdownFilter
                optionStrings={prescriberStrings}
                label={'Prescriber'}
                onChange={(prescriberName: string) => {
                  table.getColumn('prescriber_name')!.setFilterValue(prescriberName);
                }}
              />
              <TableDropdownFilter
                optionStrings={officeNameStrings}
                label={'Office'}
                onChange={(officeName: string) => {
                  table.getColumn('office_name')!.setFilterValue(officeName);
                }}
              />
            </FilterBarRow>
          )}
          {!!selectedRowIds.length && (
            <FilterBarRow>
              <BulkActionButton onClick={() => setShowAcceptModal(true)}>Accept</BulkActionButton>

              <Typography styledAs="smallBody">{selectedRowIds.length} selected</Typography>
            </FilterBarRow>
          )}
        </FilterBar>
        <Table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isSelectCol = header.id === 'selection';

                  return (
                    <TableHeader
                      key={header.id}
                      onClick={isSelectCol ? undefined : () => header.column.toggleSorting()}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {!isSelectCol && (
                        <HeaderSortingIndicator
                          isSorted={header.column.getIsSorted()}
                          sortDirection={header.column.getNextSortingOrder()}
                        />
                      )}
                    </TableHeader>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <ClickableRow
                  $selected={row.original.id === priorAuthReviewId}
                  $new={row.original.status === 'unreviewed'}
                  key={row.original.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      onClick={cell.column.id === 'selection' ? undefined : () => navigate(row.original.id)}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </ClickableRow>
              ))
            ) : (
              <tr>
                <TableCell colSpan={columns.length}>No results.</TableCell>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainer>

      {showAcceptModal && !!selectedRowIds.length ? (
        <PriorAuthSubmitModal
          onClose={() => setShowAcceptModal(false)}
          onSubmit={(checked) => acceptPriorAuthReview.mutate({ attestationCheckboxIsChecked: checked })}
          isSubmitting={acceptPriorAuthReview.isPending}
        />
      ) : null}
    </>
  );
};

export default PriorAuthReviewsTable;
