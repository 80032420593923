import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { TasksResponse, client } from '../api';
import PageContainer from '../components/PageContainer';
import TasksTable from '../components/tasks/TasksTable';
import { Outlet } from 'react-router-dom';
import { tasksQueryKey } from '../queries';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';

const TasksTablePage: React.FC = () => {
  const { data, isLoading, error } = useQuery<TasksResponse>({
    queryKey: tasksQueryKey(),
    queryFn: () => {
      return client.get(`tasks`).then((res) => res.data);
    },
  });

  let body;
  if (isLoading) {
    body = <LoadingSpinner />;
  } else if (error) {
    body = 'Error, please try again';
  } else if (data) {
    if (data.tasks.length === 0) {
      body = 'No tasks found';
    } else {
      body = <TasksTable data={data.tasks} />;
    }
  }

  return (
    <>
      <PageContainer title="Tasks">{body}</PageContainer>
      <Outlet />
    </>
  );
};

export default TasksTablePage;
