import { useState, useEffect } from 'react';
import styled from 'styled-components';
// TODO: make custom checkbox component so we can remove mui package
// https://linear.app/withtandem/issue/WIT-412/custom-checkbox-component
import { Checkbox } from '@mui/material';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage';
import { Button } from '../Button/Button';
import { attestationContent, attestationCheckboxLabel, attestationErrorMessage } from './sharedCopy';

import { Modal, ModalTitle } from 'design-system/src/components/Modal';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // Compensate for checkbox padding
  margin: -12px 0 -10px -12px;
`;

interface PriorAuthSubmitModalProps {
  onClose: () => void;
  onSubmit: (checked: boolean) => void;
  isSubmitting: boolean;
}

export const PriorAuthSubmitModal = ({ onClose, onSubmit, isSubmitting }: PriorAuthSubmitModalProps) => {
  const [checked, setChecked] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (checked && showError) {
      setShowError(false);
    }
  }, [checked]);

  const handleSubmit = () => {
    if (!checked) {
      setShowError(true);
    } else {
      onSubmit(checked);
    }
  };

  // TODO: move to shared file
  const checkboxStyling = {
    color: showError ? `var(--red)` : `var(--purple)`,
    '&.Mui-checked': {
      color: `var(--purple)`,
    },
  };

  return (
    <Modal onClose={onClose}>
      <ModalTitle>Attestation</ModalTitle>

      <Content>
        <Typography>{attestationContent}</Typography>

        <div>
          <CheckboxContainer>
            <Checkbox
              sx={checkboxStyling}
              checked={checked}
              onChange={() => setChecked(!checked)}
              aria-labelledby="prior-auth-submit-modal-checkbox-label"
            />
            <Typography id="prior-auth-submit-modal-checkbox-label">{attestationCheckboxLabel}</Typography>
          </CheckboxContainer>
          <ErrorMessage visible={showError} marginTop={'0.5rem'}>
            {attestationErrorMessage}
          </ErrorMessage>
        </div>

        <div>
          <Button onClick={handleSubmit} isLoading={isSubmitting}>
            Accept and submit
          </Button>
        </div>
      </Content>
    </Modal>
  );
};
