import DOMPurify from 'dompurify';
import styled from 'styled-components';

const StyledRenderHtmlString = styled.span`
  // Preserve newlines
  // This is interacting poorly with the <a> tags being inserted with parseUserContentString;
  // TODO: maybe consider splitting the string on newlines and wrapping the sections in
  //       <p> tags
  white-space: pre-line;

  a {
    // TODO(design-system): use Link styles when available
    color: var(--black);
    font-weight: 500;
    text-decoration-color: var(--medium-gray);
    // Pushes the underline away from the text
    text-underline-offset: 3px;
    // Moves the link to it's own line
    display: block;
  }
`;

export const parseLinksInString = (str: string): string => {
  const linkRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

  const replacer = (matched: string) => {
    let withProtocol = matched;

    if (!withProtocol.startsWith('http')) {
      withProtocol = 'http://' + matched;
    }

    // TODO: add screen reader-only text that says "Opens in a new tab"
    return `<a
      href="${withProtocol}"
      target="_blank"
      rel="noopener noreferrer"
    >
      ${matched}
    </a>`;
  };

  // Just in case there's anything malicious in the string because
  // this is inserted into an html element using dangerouslySetInnerHTML
  const sanitizedStr = DOMPurify.sanitize(str);

  return sanitizedStr.replace(linkRegex, replacer);
};

export type RenderHtmlStringProps = {
  children: string;
  className?: string;
};

/**
 * Parses a string and renders it in an HTML element.
 *
 * The string will be sanitized and urls will be converted into <a> elements that open in a new tab.
 */
export const RenderHtmlString = ({ children, className = '' }: RenderHtmlStringProps) => {
  return (
    <StyledRenderHtmlString dangerouslySetInnerHTML={{ __html: parseLinksInString(children) }} className={className} />
  );
};
