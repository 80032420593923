import React, { useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnFiltersState,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
} from '@tanstack/react-table';
import {
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  ClickableRow,
  FilterBar,
  FilterBarRow,
  SearchInput,
  HeaderSortingIndicator,
} from '../Table';
import { TableDropdownFilter } from '../TableDropdownFilter/TableDropdownFilter';
import { Drug } from '../../api';
import { useNavigate } from 'react-router-dom';

const columnHelper = createColumnHelper<Drug>();

interface RxTableProps {
  data: Array<Drug>;
}

const drugDateSortingFn = (a: Row<Drug>, b: Row<Drug>, columnId: string) => {
  return new Date(b.getValue(columnId)).getTime() - new Date(a.getValue(columnId)).getTime();
};

const RxTable: React.FC<RxTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'name', value: '' },
    { id: 'prescriber_name', value: '' },
    { id: 'patient_name', value: '' },
  ]);

  const columns = [
    columnHelper.accessor('prescription_recieved_at', {
      header: 'Prescribed on',
      cell: (info) => info.getValue(),
      filterFn: 'includesString',
      sortingFn: drugDateSortingFn,
    }),
    columnHelper.accessor('prescriber_name', {
      header: 'Prescriber',
      cell: (info) => info.getValue(),
      filterFn: 'includesString',
    }),
    columnHelper.accessor('patient_name', {
      header: 'Patient',
      cell: (info) => info.getValue(),
      filterFn: 'includesString',
    }),
    columnHelper.accessor('dob', {
      header: 'DOB',
      cell: (info) => info.getValue(),
      filterFn: 'includesString',
      sortingFn: drugDateSortingFn,
    }),
    columnHelper.accessor('name', {
      header: 'Drug',
      cell: (info) => info.getValue(),
      filterFn: 'includesString',
    }),
  ];

  const drugStrings: string[] = [];
  const prescriberStrings: string[] = [];
  const patientStrings: string[] = [];
  data.forEach((item) => {
    drugStrings.push(item.name);
    prescriberStrings.push(item.prescriber_name);
    patientStrings.push(item.patient_name);
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: 'includesString',
  });

  return (
    <TableContainer>
      <FilterBar>
        <FilterBarRow>
          <SearchInput
            label="Search by DOB, patient, etc."
            hideLabel={true}
            onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
            placement="left"
          />
          <TableDropdownFilter
            optionStrings={prescriberStrings}
            label={'Prescriber'}
            onChange={(prescriberName: string) => {
              table.getColumn('prescriber_name')!.setFilterValue(prescriberName);
            }}
          />
          <TableDropdownFilter
            optionStrings={patientStrings}
            label={'Patient'}
            onChange={(patientName: string) => {
              table.getColumn('patient_name')!.setFilterValue(patientName);
            }}
          />
          <TableDropdownFilter
            optionStrings={drugStrings}
            label={'Drug'}
            onChange={(drugName: string) => {
              table.getColumn('name')!.setFilterValue(drugName);
            }}
          />
        </FilterBarRow>
      </FilterBar>

      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader key={header.id} onClick={() => header.column.toggleSorting()}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  <HeaderSortingIndicator
                    isSorted={header.column.getIsSorted()}
                    sortDirection={header.column.getNextSortingOrder()}
                  />
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <ClickableRow key={row.id} onClick={() => navigate(`/rxs/${row.original.id}`)}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </ClickableRow>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default RxTable;
