import styled from 'styled-components';
import Typeahead from '../Typeahead';
import { TextField } from '@mui/material';

const StyledTableDropdownFilter = styled.div`
  width: 12rem;
`;

const StyledTextfield = styled(TextField)`
  > .MuiFormLabel-root {
    // This styles the placeholder label text
    font-size: 0.9rem;
    font-style: normal;
    line-height: 1rem;
    color: var(--black);
  }

  > .MuiInputBase-root {
    padding: 5px 48px 5px 9px !important;
    border-radius: 0.375rem;

    // This styles the selected option text
    color: var(--black);
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 590;
    line-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export type TableDropdownFilterProps = {
  // List of strings to display in the dropdown. Can include duplicates.
  // The component will handle transforming them into option objects.
  optionStrings: Array<string | null>;
  label: string;
  onChange: (value: string) => void;
  className?: string;
};

/**
 * A dropdown filter for use in tables
 */
export const TableDropdownFilter = ({ optionStrings, label, onChange, className }: TableDropdownFilterProps) => {
  const optionMap: { [key: string]: boolean } = {};
  const options: Array<{ value: string; description: string }> = [];

  optionStrings.forEach((optionString) => {
    if (!!optionString && !optionMap[optionString]) {
      optionMap[optionString] = true;
      options.push({ value: optionString, description: optionString });
    }
  });

  return (
    <StyledTableDropdownFilter className={className}>
      <Typeahead options={options} label={label} onChange={onChange} textfieldComponent={StyledTextfield} />
    </StyledTableDropdownFilter>
  );
};
