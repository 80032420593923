import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnFiltersState,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

import type { AppealReviewSummary, AppealReviewStatus } from '../../api';
import { TableDropdownFilter } from '../TableDropdownFilter/TableDropdownFilter';
import { sortOfficeNames } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ClickableRow,
  FilterBar,
  FilterBarRow,
  FilterGroup,
  FilterLabel,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  HeaderSortingIndicator,
  OfficeNameTableDropdownFilter,
} from '../Table';

type UITaskStatus = 'Open' | 'Reviewed';

const columnHelper = createColumnHelper<AppealReviewSummary>();

interface AppealReviewTableProps {
  data: AppealReviewSummary[];
}

const AppealReviewsTable: React.FC<AppealReviewTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const { appealReviewId } = useParams();

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([{ id: 'status', value: 'Open' }]);

  const getUIAppealReviewStatus = (status: AppealReviewStatus): UITaskStatus => {
    return status === 'unreviewed' ? 'Open' : 'Reviewed';
  };

  const prescriberStrings: string[] = [];
  const officeNameStrings: string[] = [];
  data.forEach((appealReview) => {
    prescriberStrings.push(appealReview.prescriber_name);
    if (appealReview.office_name) {
      officeNameStrings.push(appealReview.office_name);
    }
  });
  const sortedOfficeNameStrings = sortOfficeNames(officeNameStrings);

  const columns = [
    columnHelper.accessor('created_at', {
      header: 'Review Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor((row) => getUIAppealReviewStatus(row.status), {
      id: 'status',
      header: 'Status',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('patient_name', {
      header: 'Patient',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('prescriber_name', {
      header: 'Prescriber',
      enableGlobalFilter: true,
    }),
    columnHelper.accessor('drug_name', {
      header: 'Drug',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('prescription_recieved_at', {
      header: 'Rx Date',
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('office_name', {
      header: 'Office',
      enableGlobalFilter: false,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    initialState: { columnVisibility: { status: false } },
  });

  return (
    <TableContainer>
      <FilterBar>
        <FilterBarRow>
          <FilterGroup>
            <FilterLabel
              $active={table.getColumn('status')!.getFilterValue() == 'Open'}
              onClick={() => table.getColumn('status')!.setFilterValue('Open')}
            >
              Open
            </FilterLabel>
            <FilterLabel
              $active={table.getColumn('status')!.getFilterValue() == 'Reviewed'}
              onClick={() => table.getColumn('status')!.setFilterValue('Reviewed')}
            >
              Reviewed
            </FilterLabel>
          </FilterGroup>
          <SearchInput
            label="Search by name"
            hideLabel={true}
            onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
          />
        </FilterBarRow>

        <FilterBarRow>
          <TableDropdownFilter
            optionStrings={prescriberStrings}
            label={'Prescriber'}
            onChange={(prescriberName: string) => {
              table.getColumn('prescriber_name')!.setFilterValue(prescriberName);
            }}
          />
          <OfficeNameTableDropdownFilter
            optionStrings={sortedOfficeNameStrings}
            label={'Office'}
            onChange={(officeName: string) => {
              table.getColumn('office_name')!.setFilterValue(officeName);
            }}
          />
        </FilterBarRow>
      </FilterBar>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader key={header.id} onClick={() => header.column.toggleSorting()}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <HeaderSortingIndicator
                      isSorted={header.column.getIsSorted()}
                      sortDirection={header.column.getNextSortingOrder()}
                    />
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <ClickableRow
                $selected={row.original.id === appealReviewId}
                $new={row.original.status === 'unreviewed'}
                key={row.original.id}
                onClick={() => navigate(row.original.id)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </ClickableRow>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>No results.</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default AppealReviewsTable;
