import moment from 'moment';

export const formatStandardDate = (dateString: string) => {
  return moment(dateString).format('MM/DD/YYYY');
};

// Office names could be addresses, so we want to make sure anything that's an address is placed after actual office names
// An address starting with a number is our approximation of identifying an address
export const sortOfficeNames = (officeNameStrings: string[]): string[] => {
  return officeNameStrings.sort((a, b) => {
    const startsWithNumber = (str: string) => /\d/.test(str[0]);

    // If `a` starts with a number and `b` does not, `a` goes after `b`
    if (startsWithNumber(a) && !startsWithNumber(b)) return 1;

    // If `b` starts with a number and `a` does not, `b` goes after `a`
    if (!startsWithNumber(a) && startsWithNumber(b)) return -1;

    // Otherwise, sort alphabetically
    return a.localeCompare(b);
  });
};

export const truncateLongText = (text: string) => {
  if (text.length > 14) {
    return <span title={text}>{`${text.slice(0, 10)}...${text.slice(-4)}`}</span>;
  }
  return text;
};
