import React, { useCallback, useContext, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import {
  PopoverMenu,
  PopoverMenuButton,
  PopoverMenuSection,
  PopoverMenuHeading,
  PopoverMenuItems,
  PopoverMenuItem,
  PopoverMenuItemButton,
} from '../PopoverMenu';
import { FlexRow } from '../Layout';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { FileInfoResponse, fileTypes, FileType, NewCommentFile, apiHost, client } from '../../api';
import { taskFileQueryKey } from '../../queries';
import { useQuery } from '@tanstack/react-query';
import { TaskContext } from './contexts';
import downloadFile from 'design-system/src/assets/icons/downloadFile.svg';
import close from 'design-system/src/assets/icons/close.svg';
import { truncateLongText } from '../../utils';

export const UploadedFileRow = styled(FlexRow)`
  gap: 0.5rem;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const FileDisplay = styled.div<{ $solid?: boolean; $active?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid var(--border-gray);
  background: var(--white);
  margin-right: 0.5rem;
`;

const AttachedFileDisplay = styled(FileDisplay)`
  width: 190px;
  display: flex;
  flex-direction: row;
  margin-right: 0;
`;

const AttachFileButton = styled(FileDisplay)`
  width: 150px;
`;

FileDisplay.defaultProps = {
  $solid: false,
  $active: false,
};

const uploadBoxStyles = `
  display: flex;
  height: 44px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  cursor: pointer;
`;

const UploadPopoverButton = styled(PopoverMenuButton)`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px var(--purple);
  }
  ${uploadBoxStyles}
`;

const RemoveButton = styled.button`
  all: unset;
  cursor: pointer;
`;

const FileIconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 6px;
  flex-shrink: 0;
  display: flex;
  img {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
  }
`;

const FileDownloadLink: React.FC<{ isLoading: boolean; isError: boolean; fileId: string; taskId: string }> = ({
  isLoading,
  isError,
  fileId,
  taskId,
}) => {
  // TODO(design-system): when we can change icon color with CSS, use disabled
  // gray for loading and error icon
  if (isLoading || isError) {
    return (
      <FileIconContainer>
        <img src={downloadFile} alt="Download" />
      </FileIconContainer>
    );
  }

  return (
    <a href={`${apiHost}/tasks/${taskId}/file/${fileId}/download`} download>
      <FileIconContainer>
        <img src={downloadFile} alt="Download" />
      </FileIconContainer>
    </a>
  );
};

const getFileTypeUI = (fileType: FileType | string) => {
  return fileType === 'Insurance Card Both Sides' ? 'Insurance Card' : fileType;
};

const loadingText = 'Loading...';

const getFileName = (isLoading: boolean, isError: boolean, data: FileInfoResponse | undefined) => {
  if (isLoading) {
    return loadingText;
  }
  if (isError) {
    return "File isn't available. Try again later.";
  }
  return data ? truncateLongText(data.filename) : null;
};

const getFileType = (isLoading: boolean, isError: boolean, data: FileInfoResponse | undefined) => {
  if (isLoading) {
    return loadingText;
  }
  if (isError || !data) {
    return '';
  }
  const fileType = data?.tags.find((tag) => tag.name === 'Document Type')?.value;
  if (!fileType || !fileTypes.includes(fileType)) {
    return '';
  }
  return truncateLongText(getFileTypeUI(fileType));
};

interface FileUploaderProps {
  onFilesUpload: (newFiles: NewCommentFile[]) => void;
}

export const FileUploader: React.FC<FileUploaderProps> = ({ onFilesUpload }) => {
  const [uploadingFileType, setUploadingFileType] = React.useState<FileType | ''>('');

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (uploadingFileType === '') {
        return;
      }

      const filesWithType = acceptedFiles.map((file) => {
        return {
          file: file,
          document_type: uploadingFileType,
        };
      });
      onFilesUpload(filesWithType);
      setUploadingFileType('');
    },
    [uploadingFileType],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    // We're removing click and keyboard functionality so we can open our dropdown menu
    // on click instead
    noClick: true,
    noKeyboard: true,
    // We're overriding drag for now because it's not working with our popover menu
    noDrag: true,
    // Make sure to reset the state if the user cancels out of the file selection dialog
    onFileDialogCancel: () => setUploadingFileType(''),
  });

  useEffect(() => {
    // We're passing the file type to the file uploader via state + useEffect
    // because dropzone doesn't pass the button target information along
    if (!!uploadingFileType) {
      open();
    }
  }, [uploadingFileType]);

  return (
    <AttachFileButton {...getRootProps()}>
      <input {...getInputProps()} />

      <PopoverMenu>
        <UploadPopoverButton>Attach file</UploadPopoverButton>

        <PopoverMenuItems>
          <PopoverMenuSection>
            <PopoverMenuHeading>What type of file are you uploading?</PopoverMenuHeading>

            {fileTypes.map((fileType: FileType) => {
              return (
                <PopoverMenuItem key={`file-type-option-${fileType}`}>
                  <PopoverMenuItemButton
                    data-file-type={fileType}
                    onClick={(event) => {
                      const target = event.target as HTMLElement;
                      const fileType = target.dataset.fileType as FileType;
                      setUploadingFileType(fileType);
                    }}
                  >
                    {getFileTypeUI(fileType)}
                  </PopoverMenuItemButton>
                </PopoverMenuItem>
              );
            })}
          </PopoverMenuSection>
        </PopoverMenuItems>
      </PopoverMenu>
    </AttachFileButton>
  );
};

const FileInfo = styled.div`
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 400;
  line-height: 114.286%;
  padding: 0.5rem;
  padding-left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 118px;
`;

const FileName = styled.div`
  color: var(--black);
  font-weight: 500;
`;
interface UploadedFileProps {
  filename: string;
  fileType: FileType;
  onFileDelete: (filename: string, document_type: FileType) => void;
}

// A file that the user has selected to submit with their
// task comment
export const UploadedFile: React.FC<UploadedFileProps> = ({ filename, fileType, onFileDelete }) => {
  const filenameForUI = truncateLongText(filename);
  const fileTypeForUI = truncateLongText(getFileTypeUI(fileType));

  return (
    <AttachedFileDisplay>
      <RemoveButton onClick={() => onFileDelete(filename, fileType)}>
        <FileIconContainer>
          <img src={close} alt="Remove file" />
        </FileIconContainer>
      </RemoveButton>

      <FileInfo>
        <FileName>{filenameForUI}</FileName>
        <Typography styledAs="smallBody">{fileTypeForUI}</Typography>
      </FileInfo>
    </AttachedFileDisplay>
  );
};

// A file that is attached to a comment that has been successfully submitted
export const AttachedFile: React.FC<{ fileId: string }> = ({ fileId }) => {
  const { taskId } = useContext(TaskContext);

  const query = useQuery<FileInfoResponse>({
    queryKey: taskFileQueryKey(fileId),
    queryFn: async () => {
      const res = await client.get(`/tasks/${taskId}/file/${fileId}`);
      return res.data;
    },
  });

  const filename = getFileName(query.isLoading, query.isError, query.data);
  const fileType = getFileType(query.isLoading, query.isError, query.data);

  return (
    <AttachedFileDisplay>
      <FileDownloadLink isLoading={query.isLoading} isError={query.isError} fileId={fileId} taskId={taskId} />
      <FileInfo>
        <FileName>{filename}</FileName>
        <Typography styledAs="smallBody">{fileType}</Typography>
      </FileInfo>
    </AttachedFileDisplay>
  );
};
