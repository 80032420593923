import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { Typography } from 'design-system/src/components/Typography/Typography';
import chevronRight from 'design-system/src/assets/icons/chevronRight.svg';
import downloadTray from 'design-system/src/assets/icons/downloadTray.svg';
import { formatStandardDate } from '../utils';
import PageContainer from '../components/PageContainer';
import { client, apiHost } from '../api';
import { truncateLongText } from '../utils';
import type { RxTask, RxDocument, RxResponse, RxTaskType, RxTaskListType } from '../api';
import { ItemViewTopNav } from '../components/tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
  Hairline,
} from '../components/tableItemDetailViewComponents/Containers';
import { Sidebar, SidebarTopBar } from '../components/sidebar/Sidebar';
import {
  DrugPanel,
  PatientPanel,
  PrescriberPanel,
  PharmacyPanel,
  InsurancePanel,
} from '../components/sidebar/SidebarPanels';
import { ExtraInfoTable } from '../components/ExtraInfoTable/ExtraInfoTable';

import { flexRender, getCoreRowModel, useReactTable, getSortedRowModel } from '@tanstack/react-table';

import { Link } from 'react-router-dom';
import { Row, Table, TableCell, TableContainer, TableHeader, HeaderSortingIndicator } from '../components/Table';
import { Button } from '../components/Button/Button';
import { ToggleButton, ToggleButtonContainer } from '../components/ToggleButton/ToggleButton';
import { FlexRow } from 'design-system/src/components/Layout';

const Headline = styled(Typography)`
  margin-bottom: 1rem;
`;

const LeftSide = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

const TasksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Task = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--border-radius-medium, 6px);
  border: 1px solid var(--border-gray);
  background: var(--white);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-out;

  &:hover {
    background: var(--light-border-gray);
  }
`;
const CreateRequestButton = styled(Button)`
  display: inline-flex;
  flex-direction: column;
  padding: 0.25rem 0.75rem;
  height: 36px;
  border-radius: 6px;
  border: 1px solid var(--border-gray);
  background: var(--white);
  transition: background-color 0.2s ease-out;
  color: var(--black);

  &:hover {
    background: var(--light-border-gray);
  }
`;

const TitleRow = styled(FlexRow)`
  margin-bottom: 1rem;
`;

const DownloadFileCell = styled(TableCell)`
  box-sizing: border-box;
  // Keeps cell from expanding even if table is less wide than
  // the available space
  width: 52px;
`;

const DownloadFileContainer = styled.a`
  display: block;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s ease-out;

  &:hover {
    background: var(--light-border-gray);
  }
`;

const getFileDownloadUrl = (rxId: string, fileId: string) => {
  return `${apiHost}/rxs/${rxId}/file/${fileId}/download`;
};

interface DocumentTableProps {
  rxId: string;
  data: RxDocument[];
}

// TODO: query for the document name instead of getting it from the main data call?
const DocumentsTable = ({ rxId, data }: DocumentTableProps) => {
  const columns = [
    {
      header: 'Date',
      accessorKey: 'date',
      id: 'date',
      // TODO: fix `any`
      cell: (info: any) => new Date(info.getValue()).toLocaleDateString(),
    },
    {
      header: 'Document type',
      accessorKey: 'document_type',
      id: 'document_type',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      id: 'name',
      cell: (info: any) => truncateLongText(info.getValue()),
    },
    {
      header: '',
      id: 'download',
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <TableContainer>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader key={header.id} onClick={() => header.column.toggleSorting()}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {header.id !== 'download' && (
                      <HeaderSortingIndicator
                        isSorted={header.column.getIsSorted()}
                        sortDirection={header.column.getNextSortingOrder()}
                      />
                    )}
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <Row key={row.original.id}>
                {row.getVisibleCells().map((cell) => {
                  if (cell.column.id === 'download') {
                    return (
                      <DownloadFileCell key={cell.id}>
                        <DownloadFileContainer href={getFileDownloadUrl(rxId, row.original.id)} download>
                          <img src={downloadTray} alt="download" title="Download" />
                        </DownloadFileContainer>
                      </DownloadFileCell>
                    );
                  } else {
                    return (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    );
                  }
                })}
              </Row>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>No documents.</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const RxPage: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [hideClosedTasks, setHideClosedTasks] = useState(true);
  const navigate = useNavigate();

  const params = useParams();
  const query = useQuery<RxResponse>({
    queryKey: ['rxs', params.rxId],
    queryFn: () => {
      return client.get(`rxs/${params.rxId}`).then((res) => res.data);
    },
  });

  const queryClient = useQueryClient();

  const getTaskUrl = (taskType: RxTaskType, id: string) => {
    let table = 'tasks';
    if (taskType === 'request') {
      table = 'requests';
    } else if (taskType === 'appeal_review') {
      table = 'appeal-reviews';
    } else if (taskType === 'prior_auth_review') {
      table = 'prior-auth-reviews';
    }

    return `/${table}/${id}`;
  };

  const getTasksHeadlineCopy = (task_list_type: RxTaskListType) => {
    if (task_list_type === 'all') {
      return 'Tasks and reviews';
    }
    if (task_list_type === 'tasks') {
      // TODO: after tasks and requests tables have merged, change this to "Tasks"
      return 'Tasks and requests';
    }
    if (task_list_type === 'reviews') {
      return 'Reviews';
    }
    return null;
  };

  const getNoTasksCopy = (task_list_type: RxTaskListType) => {
    if (task_list_type === 'all') {
      return 'No tasks or reviews';
    }
    if (task_list_type === 'tasks') {
      // TODO: after tasks and requests tables have merged, change this to "No tasks"
      return 'No tasks or requests';
    }
    if (task_list_type === 'reviews') {
      return 'No reviews';
    }
    return null;
  };

  let body;
  if (query.isLoading) {
    body = <LoadingSpinner />;
  } else if (query.error) {
    body = 'Error, please try again';
  } else if (query.data) {
    const { statuses, task_list_type, tasks, documents } = query.data.rx;

    const name = `${query.data.patient.first_name} ${query.data.patient.last_name}`;
    const dob = query.data.patient.date_of_birth;
    const prescriber = `${query.data.prescriber.first_name} ${query.data.prescriber.last_name}`;
    const prescribedOn = query.data.drug.prescription_recieved_at;

    const generalData = [
      { label: 'Patient', value: `${name} (${formatStandardDate(dob)})` },
      { label: 'Prescribed by', value: `${prescriber} on ${formatStandardDate(prescribedOn)}` },
    ];
    const statusData = [
      { label: 'PA', value: statuses.pa },
      { label: 'Appeal', value: statuses.appeal },
      { label: 'Pharmacy', value: statuses.pharmacy },
      { label: 'Enrollment', value: statuses.bridge },
      { label: 'PAP', value: statuses.pap },
    ];
    const taskList = hideClosedTasks ? tasks.filter((task) => task.status === 'open') : tasks;

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed} />

          <ContentOuterWrapper>
            <CenteredContent>
              <Headline styledAs="title">{query.data.drug.name}</Headline>
              <ExtraInfoTable data={generalData} />
              <ExtraInfoTable data={statusData} />

              {task_list_type !== 'none' && (
                <>
                  <TitleRow>
                    <LeftSide>
                      <Typography styledAs="largeBody" weight={500}>
                        {getTasksHeadlineCopy(task_list_type)}
                      </Typography>
                      <ToggleButtonContainer>
                        <ToggleButton
                          onClick={() => setHideClosedTasks(false)}
                          isChecked={!hideClosedTasks}
                          ariaLabel="Show all tasks"
                        >
                          <Typography renderedAs="span" styledAs="smallBody">
                            All
                          </Typography>
                        </ToggleButton>
                        <ToggleButton
                          onClick={() => setHideClosedTasks(true)}
                          isChecked={hideClosedTasks}
                          ariaLabel="Only show open tasks"
                        >
                          <Typography renderedAs="span" styledAs="smallBody">
                            Open
                          </Typography>
                        </ToggleButton>
                      </ToggleButtonContainer>
                    </LeftSide>

                    {(task_list_type === 'all' || task_list_type === 'tasks') && (
                      <CreateRequestButton
                        onClick={() => {
                          navigate(`new-request?patientId=${query.data.patient.id}&source=portal_rx_page`);
                        }}
                      >
                        Create request
                      </CreateRequestButton>
                    )}
                  </TitleRow>

                  <TasksContainer>
                    {!taskList.length ? (
                      <Typography styledAs="smallBody">{getNoTasksCopy(task_list_type)}</Typography>
                    ) : null}
                    {taskList.map((task: RxTask) => {
                      const taskUrl = getTaskUrl(task.type, task.id);

                      return (
                        <Task to={taskUrl} key={`task-${task.id}`}>
                          <div>
                            <Typography styledAs="smallBody" weight={500}>
                              {task.sub_type}
                            </Typography>
                            <Typography styledAs="extraSmallBody">
                              Created {moment(task.created_at).format('MM/DD/YY')}
                            </Typography>
                          </div>
                          <img src={chevronRight} alt="" />
                        </Task>
                      );
                    })}
                  </TasksContainer>

                  <Hairline />
                </>
              )}

              <Headline styledAs="largeBody" weight={500}>
                Documents
              </Headline>
              <DocumentsTable rxId={params.rxId as string} data={documents} />
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>

        {!isSidebarCollapsed && (
          <Sidebar>
            <SidebarTopBar />
            <DrugPanel drug={query.data.drug} />
            <PharmacyPanel pharmacy={query.data.pharmacy} />
            <PatientPanel patient={query.data.patient} />
            <PrescriberPanel prescriber={query.data.prescriber} />
            <InsurancePanel insurance={query.data.insurance} />
          </Sidebar>
        )}
      </BodyContainer>
    );
  }

  return (
    <>
      <PageContainer noPadding={true}>{body}</PageContainer>
      <Outlet
        context={{
          reload: () => queryClient.resetQueries({ queryKey: ['rxs', params.rxId] }),
        }}
      />
    </>
  );
};

export default RxPage;
