import styled from 'styled-components';

export const ControllerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 0;
  }
`;
