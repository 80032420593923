import axios from 'axios';

export const apiHost = import.meta.env.VITE_API_HOST;

export const client = axios.create({
  baseURL: apiHost,
  withCredentials: true,
});

export const url = (path: string) => {
  return `${apiHost}${path}`;
};

export interface ErrorResponse {
  error?: string;
}

/** Task Page */

export type TaskStatus = 'queued' | 'completed' | 'processing' | 'cancelled';

export interface TaskSummary {
  drug_name: string;
  drug_quantity: string;
  drug_sig: string;
  id: string;
  patient_name: string;
  patient_dob: string;
  prescriber_name: string;
  prescription_recieved_at: string;
  task_created_at: string;
  task_read_status: 'read' | 'unread';
  task_status: TaskStatus;
  task_type: string;
  office_name: string | null;
}

export interface TasksResponse {
  tasks: TaskSummary[];
}

export interface TaskComment {
  id: string;
  task_id: string;
  direction: 'inbound' | 'outbound';
  message: string;
  user: string;
  attachment_document_ids: string[];
  created_at: string;
}

export type TaskType =
  | 'Rx Clarification'
  | 'Document Upload'
  | 'PA Denial'
  | 'Form Signature Request'
  | 'Affordability Issue'
  | 'New Rx Request'
  | 'Unresponsive Patient'
  | 'PAP/Bridge'
  | 'Additional Clinical Information'
  | 'PA Review'
  | 'Appeal Review'
  | 'Other';

export interface Task {
  id: string;
  status: TaskStatus;
  created_at: string;
  files: string[];
  type: TaskType;
  // TODO: remove after the task and request tables have been merged
  direction: 'inbound' | 'outbound';
  comments: TaskComment[];
  first_name?: string;
  last_name?: string;
  dob?: string;
}

export interface Address {
  city: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
}
export interface Patient {
  id: string;
  address: Address;
  date_of_birth: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface Prescriber {
  first_name: string;
  last_name: string;
  npi: string;
  phone_number: string;
  fax_number: string;
  address: Address;
}

export interface Drug {
  id: string;
  name: string;
  quantity: string;
  sig: string;
  ndc: string;
  refills: string;
  prescription_recieved_at: string;
  prescriber_name: string;
  patient_name: string;
  dob: string;
}
export interface TaskResponse {
  task: Task;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
}

export const fileTypes = [
  'Medical Records',
  'Lab Results',
  'Insurance Card Both Sides',
  'PA Denial Letter',
  'PA Approval Letter',
  'Other',
] as const;

export type FileType = (typeof fileTypes)[number];

export type NewCommentFile = {
  file: File;
  document_type: FileType;
};

interface FileInfoTag {
  name: string;
  value: FileType;
}

export type FormFilesDocumentTypes = {
  // The key will be a filename (like 'cat1.jpg')
  [key: string]: FileType;
};

export interface FileInfoResponse {
  id: string;
  filename: string;
  success: boolean;
  tags: FileInfoTag[];
}

/** Prior Auth Page */

export type PADecision = 'PENDING' | 'APPROVAL' | 'ALREADY_APPROVED' | 'DENIAL' | 'PARTIAL_APPROVAL' | 'NOT_REQUIRED';

export type PASubmissionMode = 'CMM' | 'FAX' | 'PHONE' | 'OTHER_TOOL';

export interface PriorAuth {
  id: string;
  submitted_on_date: string;
  request_type: string;
  decision: PADecision;
  approval_until: string;
  submission_mode: PASubmissionMode;
}

export interface PriorAuthTableResponseRow {
  pa: PriorAuth;
  rx: Drug;
  office_name: string;
  drug_name: string;
}

export interface PriorAuthTableRow {
  formatted_prescription_recieved_at: string;
  formatted_approval_until: string;
  formatted_dob: string;
  office_name: string;
  drug_name: string;
  pa: PriorAuth;
  rx: Drug;
}
export interface PriorAuthResponse {
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
  pa: PriorAuth;
}

/** Request Table Page */

export type RequestType = 'Authorization Update' | 'PAP/Bridge Update' | 'Pharmacy' | 'PA Renewal' | 'Other';

export interface RequestSummary {
  id: string;
  text: string;
  patient_name: string;
  // The prescriber and office names will be null on older requests
  prescriber_name: string | null;
  office_name: string | null;
  dob: string;
  created_at: string;
  status: string;
  type: RequestType;
}

/** Appeal Review Page */

export type AppealReviewStatus = 'unreviewed' | 'accepted' | 'rejected' | 'redrafted';

// A row in the appeal reviews table
export interface AppealReviewSummary {
  id: string;
  drug_name: string;
  patient_name: string;
  prescriber_name: string;
  prescription_recieved_at: string;
  status: AppealReviewStatus;
  created_at: string;
  office_name: string | null;
}

// All rows in the appeal reviews table
export interface AppealReviewsResponse {
  appeal_reviews: AppealReviewSummary[];
}

export interface AppealReview {
  id: string;
  status: AppealReviewStatus;
  reviewed_by?: string;
  reviewed_at?: string;
  reject_justification?: string;
}

// Info in the appeal review detail view
export interface AppealReviewResponse {
  appeal_review: AppealReview;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
}

/** Prior Auth Review Page */

export type PriorAuthReviewStatus = 'unreviewed' | 'editing' | 'edited' | 'submitted';

export type PriorAuthReviewFormStatus =
  | 'FORM_STATUS_UNKNOWN'
  | 'FORM_STATUS_NOT_STARTED'
  | 'FORM_STATUS_IN_PROGRESS'
  | 'FORM_STATUS_COMPLETED'
  | 'FORM_STATUS_SUBMITTED';

// A row in the prior auth reviews table
export interface PriorAuthReviewSummary {
  id: string;
  drug_name: string;
  patient_name: string;
  prescriber_name: string;
  prescription_recieved_at: string;
  status: PriorAuthReviewStatus;
  created_at: string;
  office_name: string;
}

// All rows in the prior auth reviews table
export interface PriorAuthReviewsResponse {
  prior_auth_reviews: PriorAuthReviewSummary[];
}

export interface PriorAuthReview {
  id: string;
  status: PriorAuthReviewStatus;
  reviewed_by?: string;
  reviewed_at?: string;
  submission_form_tree_state_id: string;
}

// Info in the appeal review detail view
export interface PriorAuthReviewResponse {
  prior_auth_review: PriorAuthReview;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
}

type PriorAuthReviewFormAnswerType =
  | 'ANSWER_TYPE_UNKNOWN'
  | 'ANSWER_TYPE_CHECKBOX'
  | 'ANSWER_TYPE_DATE'
  | 'ANSWER_TYPE_RADIO'
  | 'ANSWER_TYPE_TEXT'
  | 'ANSWER_TYPE_JUSTIFICATION';

type PriorAuthReviewFormQuestion = {
  identifier: string;
  description: string;
  answer_type: PriorAuthReviewFormAnswerType;
  is_answered?: boolean;
};

export type PriorAuthReviewFormAnswer = {
  identifier: string;
  description?: string;
  answer_type: PriorAuthReviewFormAnswerType;
  regex_validations?: string;
  is_selected?: boolean; // if the question is answered + this answer is selected = True
  answer_input_text?: string; // populated for TEXT + DATE types
  answer_choice_text?: string; // this was not in the Retool code
};

export type PriorAuthReviewFormQuestionAnswer = {
  question: PriorAuthReviewFormQuestion;
  answer_options: PriorAuthReviewFormAnswer[];
};

export interface PriorAuthReviewFormData {
  form: {
    ordered_question_answers: PriorAuthReviewFormQuestionAnswer[];
  };
  form_status: PriorAuthReviewFormStatus;
}

type PriorAuthReviewFormAnswerSelection = {
  answer_identifier: string;
  // Passed in if answer_type is text or date
  answer_text?: string;
};

export type PriorAuthReviewFormUpdateData = {
  question_identifier: string;
  // If selected_answers is undefined, skip_question should be true
  selected_answers?: PriorAuthReviewFormAnswerSelection[];
  skip_question?: boolean;
};

/** Rx Page */
export type RxTaskType = 'task' | 'request' | 'appeal_review' | 'prior_auth_review';

export type RxTaskSubtype = TaskType | RequestType;

export type RxTaskListType = 'all' | 'tasks' | 'reviews' | 'none';

export interface RxTask {
  id: string;
  status: 'open' | 'closed';
  type: RxTaskType;
  sub_type: RxTaskSubtype;
  created_at: string;
}

export interface RxDocument {
  id: string;
  name: string;
  date: string;
  document_type: DocumentType;
}

export interface Rx {
  name: string;
  task_list_type: RxTaskListType;
  statuses: {
    appeal: string;
    bridge: string;
    pa: string;
    pap: string;
    pharmacy: string;
  };
  tasks: RxTask[];
  documents: RxDocument[];
}

export interface Pharmacy {
  name: string;
  npi: string;
  phone: string;
  fax: string;
  address: Address;
}

export interface Insurance {
  name: string;
  phone: string;
  fax: string;
}

export interface RxResponse {
  rx: Rx;
  patient: Patient;
  prescriber: Prescriber;
  drug: Drug;
  insurance: Insurance;
  pharmacy: Pharmacy;
  prescription_recieved_at: string;
  dob: string;
  drug_name: string;
  prescriber_name: string;
  patient_name: string;
}

/** Notification types */
export type NotificationSettings = {
  email_address?: string;
  notifications?: boolean;
  npis?: string[];
};

export type StytchMember = {
  id: string;
  email_address: string;
  trusted_metadata: NotificationSettings;
};

export type NotificationData = {
  members: StytchMember[];
  providers: { npi: string; name: string }[];
};

export type NotificationUpdateData = {
  [member_id: string]: NotificationSettings;
};
