import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';
import React from 'react';

export const ToggleButtonContainer = styled.div.attrs({
  role: 'group',
})`
  display: flex;
  gap: 1rem;
  // Push the toggle buttons down to line up better with the other text
  margin-bottom: -2px;
`;

const StyledToggleButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-bottom: 1px;
  padding: 2px 0;

  &[aria-checked='true'] {
    margin-bottom: 0;
    border-bottom: 1px solid var(--black);
  }
`;

export interface ToggleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick: () => void;
  isChecked: boolean;
  ariaLabel?: string;
}

/**
 * Button that toggles between 2 states; styled to look like tabs
 */
export const ToggleButton = ({ children, onClick, isChecked, ariaLabel }: ToggleButtonProps) => {
  return (
    <StyledToggleButton onClick={onClick} aria-checked={isChecked} aria-label={ariaLabel} role="switch">
      <Typography renderedAs="span" styledAs="smallBody">
        {children}
      </Typography>
    </StyledToggleButton>
  );
};
