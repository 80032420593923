import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { UseMutateFunction } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { PriorAuthReviewFormStatus } from '../../../api.tsx';
import type { PriorAuthReviewFormQuestionAnswer, PriorAuthReviewFormUpdateData } from '../../../api.tsx';
import { TextField, Radio, Checkbox, DateInput, NonQuestion } from './FormTreeComponents.tsx';
import { Typography } from 'design-system/src/components/Typography/Typography';
// TODO: make custom checkbox component so we can remove mui package
// https://linear.app/withtandem/issue/WIT-412/custom-checkbox-component
import { Checkbox as MuiCheckbox } from '@mui/material';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage.tsx';
import { attestationContent, attestationCheckboxLabel, attestationErrorMessage } from '../sharedCopy.ts';

const StyledCustomPaForm = styled.div`
  * {
    box-sizing: border-box;
    font-weight: normal;
  }
`;

const NoQuestions = styled.div`
  background-color: var(--light-border-gray);
  padding: 0.75rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
`;

const Question = styled.div`
  padding: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--light-border-gray);
  }
`;

const QuestionHeader = styled.div`
  font-weight: 500;
  margin-bottom: 0.5rem;

  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const AttestationForm = styled.div`
  margin: 1rem;
  padding: 1rem;
  // This is a one-off because we don't have a design system gray
  // that's light enough to use as a background color with text
  // on top
  background-color: rgba(239, 237, 241, 0.5);
  border-radius: var(--border-radius-medium);
`;

const AttestationCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // Compensate for checkbox padding
  margin: 0 0 -10px -12px;
`;

const NONQUESTION_PREFIX = 'tandem_nonquestion_';

const getIsNonQuestion = (questionId: string) => {
  return questionId.startsWith(NONQUESTION_PREFIX);
};

/* Ensure date format matches input type="date" */
// TODO: use moment.js
function formatDate(dateString: string) {
  if (dateString === '') return '';
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Extract year, month, and day
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // getMonth returns 0-11
  let day = date.getDate();

  // Format month and day to ensure they are two digits
  // @ts-ignore we should use moment here anyway
  month = month < 10 ? '0' + month : month;
  // @ts-ignore we should use moment here anyway
  day = day < 10 ? '0' + day : day;

  // Return the formatted date string
  return `${year}-${month}-${day} `;
}

export type CustomPaFormProps = {
  form: {
    ordered_question_answers: PriorAuthReviewFormQuestionAnswer[];
  };
  formStatus: PriorAuthReviewFormStatus;
  formId: string;
  saveQuestionAnswer: UseMutateFunction<any, AxiosError<unknown, any>, PriorAuthReviewFormUpdateData, unknown>;
  attestationCheckboxIsChecked: boolean;
  setAttestationCheckboxIsChecked: Dispatch<SetStateAction<boolean>>;
  showAttestationCheckboxError: boolean;
  setShowAttestationCheckboxError: Dispatch<SetStateAction<boolean>>;
  attestationCheckboxErrorMessageId: string;
};

/**
 * Prior authorization form component that displays the form questions and allows the user to answer them.
 */
export const CustomPaForm = ({
  form,
  formStatus,
  formId,
  saveQuestionAnswer,
  attestationCheckboxIsChecked,
  setAttestationCheckboxIsChecked,
  showAttestationCheckboxError,
  setShowAttestationCheckboxError,
  attestationCheckboxErrorMessageId,
}: CustomPaFormProps) => {
  const formData = form.ordered_question_answers;
  const disabled = formStatus === 'FORM_STATUS_SUBMITTED';

  // For tracking the selection changes users make before sending the answers to the server.
  // This allows the component to respond to the user immediately instead of waiting for a
  // server response every time they interact with an input.
  const [formDataInState, setFormDataInState] = useState<PriorAuthReviewFormQuestionAnswer[]>(formData);
  // Track whether the form has fully rendered for the first time.
  const hasRenderedForm = useRef(false);

  // TODO: move to shared file
  const attestationCheckboxStyling = {
    color: showAttestationCheckboxError ? `var(--red)` : `var(--purple)`,
    '&.Mui-checked': {
      color: `var(--purple)`,
    },
  };

  const handleSaveRadio = (questionIdentifier: string, answerIdentifier: string, questionIndex: number) => {
    saveQuestionAnswer({
      question_identifier: questionIdentifier,
      selected_answers: [{ answer_identifier: answerIdentifier }],
    });

    const allOptionsInState = formDataInState[questionIndex].answer_options;
    const selectedOptionIndex = allOptionsInState.findIndex((option) => option.identifier === answerIdentifier);
    const newFormData = formDataInState;
    newFormData[questionIndex].answer_options[selectedOptionIndex].is_selected = true;
    setFormDataInState(newFormData);
  };

  const handleSaveCheckbox = (questionIdentifier: string, answerIdentifier: string, questionIndex: number) => {
    const question = formDataInState[questionIndex];
    const allOptionsInState = question.answer_options;
    const selectedOptionIndex = allOptionsInState.findIndex((option) => option.identifier === answerIdentifier);
    allOptionsInState[selectedOptionIndex].is_selected = !allOptionsInState[selectedOptionIndex].is_selected;
    const selectedAnswers = allOptionsInState
      .filter((option) => option.is_selected)
      .map((option) => ({ answer_identifier: option.identifier }));

    if (!selectedAnswers.length) {
      saveQuestionAnswer({ question_identifier: questionIdentifier, skip_question: true });
    } else {
      saveQuestionAnswer({ question_identifier: questionIdentifier, selected_answers: selectedAnswers });
    }
  };

  // handleTextSave covers text, justification, and date types
  const handleTextSave = (
    questionIdentifier: string,
    answerIdentifier: string,
    _questionIndex: number,
    answerText: string,
  ) => {
    saveQuestionAnswer({
      question_identifier: questionIdentifier,
      selected_answers: [{ answer_identifier: answerIdentifier, answer_text: answerText }],
    });
  };

  useEffect(() => {
    setFormDataInState(formData);
  }, [form]);

  useEffect(() => {
    // We want to wait until after the form has fully rendered
    if (!hasRenderedForm.current) {
      if (formData.length) {
        // We need a brief pause before actually setting hasRenderedForm to true
        // to skip an annoying 2nd render that's picking this up
        setTimeout(() => {
          hasRenderedForm.current = true;
        }, 0);
      }
      return;
    }

    const formContainer = document.getElementById(formId);
    if (formContainer) {
      // The setTimeout adds a slight delay, which gives the browser time to rerender the container
      // before we try to scroll it
      setTimeout(() => {
        formContainer.scrollTop = formContainer.scrollHeight;
      }, 0);
    }
  }, [formData.length]);

  if (formDataInState.length === 0) {
    return (
      <StyledCustomPaForm>
        <NoQuestions>No clinical questions found</NoQuestions>
      </StyledCustomPaForm>
    );
  }

  // We track the question number displayed separately from the index because we
  // don't render the non-questions in the list
  let questionNumberForUI = 1;

  return (
    <StyledCustomPaForm>
      {formDataInState.map((questionAnswer, index) => {
        const { question, answer_options } = questionAnswer;

        if (getIsNonQuestion(question.identifier)) {
          if (index === formDataInState.length - 1) {
            // Inserting the NonQuestion component in the form
            // will cause it to automatically save this question,
            // which gets us the next question in the form
            return (
              <NonQuestion
                answer={answer_options[0]}
                questionId={question.identifier}
                questionIndex={index}
                disabled={disabled}
                onSave={handleTextSave}
              />
            );
          }
          return null;
        }

        return (
          <Question key={question.identifier}>
            <label>
              <QuestionHeader>{'Question ' + questionNumberForUI++}</QuestionHeader>
              {question.description}
            </label>
            {question.answer_type === 'ANSWER_TYPE_TEXT' || question.answer_type === 'ANSWER_TYPE_JUSTIFICATION' ? (
              <TextField
                answer={answer_options[0]}
                questionId={question.identifier}
                questionIndex={index}
                disabled={disabled}
                onSave={handleTextSave}
              />
            ) : question.answer_type === 'ANSWER_TYPE_DATE' ? (
              <DateInput
                type="date"
                onChange={(event) => {
                  handleTextSave(question.identifier, answer_options[0].identifier, index, event.target.value);
                }}
                value={formatDate(answer_options[0].answer_input_text || '')}
                disabled={disabled}
              />
            ) : question.answer_type === 'ANSWER_TYPE_RADIO' ? (
              <Radio
                answers={answer_options}
                questionId={question.identifier}
                questionIndex={index}
                disabled={disabled}
                onChange={handleSaveRadio}
                allowSkip={false}
              />
            ) : question.answer_type === 'ANSWER_TYPE_CHECKBOX' ? (
              <Checkbox
                answers={answer_options}
                questionId={question.identifier}
                questionIndex={index}
                disabled={disabled}
                onChange={handleSaveCheckbox}
                allowSkip={false}
              />
            ) : undefined}
          </Question>
        );
      })}

      {formStatus !== 'FORM_STATUS_SUBMITTED' && (
        <AttestationForm>
          <Typography styledAs="smallBody">{attestationContent}</Typography>

          <AttestationCheckboxContainer>
            <MuiCheckbox
              sx={attestationCheckboxStyling}
              checked={attestationCheckboxIsChecked}
              onChange={() => {
                // The checkbox is about to be toggled, which is why we're checking if the
                // checkbox is NOT checked to determine if we can remove the error message
                if (!attestationCheckboxIsChecked && showAttestationCheckboxError) {
                  setShowAttestationCheckboxError(false);
                }
                setAttestationCheckboxIsChecked(!attestationCheckboxIsChecked);
              }}
              aria-labelledby="prior-auth-form-attestation-checkbox-label"
            />
            <Typography id="prior-auth-form-attestation-checkbox-label">{attestationCheckboxLabel}</Typography>
          </AttestationCheckboxContainer>
          <ErrorMessage
            visible={showAttestationCheckboxError}
            id={attestationCheckboxErrorMessageId}
            marginTop={'0.5rem'}
          >
            {attestationErrorMessage}
          </ErrorMessage>
        </AttestationForm>
      )}
    </StyledCustomPaForm>
  );
};
