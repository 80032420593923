import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { Hairline } from '../tableItemDetailViewComponents/Containers';

const StyledExtraInfoTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1.5rem 0;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const FieldLabel = styled(Typography).attrs({ styledAs: 'smallBody' })`
  color: var(--dark-gray);
  min-width: 100px;
  padding-right: 1rem;
`;

export type ExtraInfoTableProps = {
  data: Array<{ label: string; value: string | null }>;
};

/**
 * Table for displaying extra content at the top of the page.
 * Pass through a list to ensure the ordering of rows.
 */
export const ExtraInfoTable = ({ data }: ExtraInfoTableProps) => {
  return (
    <div>
      <StyledExtraInfoTable>
        {data.map((item) => (
          <FieldGroup key={item.label}>
            <FieldLabel>{item.label}</FieldLabel>
            <Typography>{item.value || '-'}</Typography>
          </FieldGroup>
        ))}
      </StyledExtraInfoTable>

      <Hairline />
    </div>
  );
};
