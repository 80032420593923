import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from 'design-system/src/components/Toast/Toast';
// TODO: make custom checkbox component so we can remove mui package
// https://linear.app/withtandem/issue/WIT-412/custom-checkbox-component
import { Checkbox } from '@mui/material';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage';
import { client } from '../../../api';
import { AcceptTermsFormData } from '../../../types';
import { Button } from '../../Button/Button';
import { PrivacyPolicy } from '../../PrivacyPolicy/PrivacyPolicy';
import { FormContainer } from '../FormContainer/FormContainer';

const StyledPrivacyPolicy = styled(PrivacyPolicy)`
  // The markdown automatically has some extra margin built-in
  // that's a little awkward in the context of this page
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
`;

const StyledAcceptTerms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DocumentLink = styled.a`
  // TODO(design-system): use Link styles when available
  color: var(--black);
  font-weight: 500;
  text-decoration-color: var(--medium-gray);
  // Pushes the underline away from the text
  text-underline-offset: 3px;
`;

const SubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  width: fit-content;
`;

export type AcceptTermsProps = {
  isSubmitting: boolean;
  onBack: () => void;
  onContinue: (formData: AcceptTermsFormData) => void;
  setUserSubmittedForm: (submitted: boolean) => void;
};

/**
 * The signup page where the user can accept the terms of service.
 */
export const AcceptTerms = ({ isSubmitting, onBack, onContinue, setUserSubmittedForm }: AcceptTermsProps) => {
  const [acceptCheckboxIsChecked, setAcceptCheckboxIsChecked] = useState(false);
  const [showError, setShowError] = useState(false);

  const query = useQuery({
    queryKey: ['documentVersions'],
    queryFn: () => {
      return client
        .get('/signup/document-versions')
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          toast.custom(() => <Toast variant="error">An error occurred</Toast>);
        });
    },
  });

  useEffect(() => {
    if (acceptCheckboxIsChecked && showError) {
      setShowError(false);
    }
  }, [acceptCheckboxIsChecked]);

  const handleSubmit = () => {
    if (!query.data) {
      toast.custom(() => <Toast variant="error">An error occurred</Toast>);
      return;
    }

    if (!acceptCheckboxIsChecked) {
      setShowError(true);
    } else {
      setUserSubmittedForm(true);
      onContinue({
        accepted_terms_at: new Date().toISOString(),
        tos_version_accepted: query.data.tos_version,
        baa_version_accepted: query.data.baa_version,
        aa_version_accepted: query.data.aa_version,
      });
    }
  };

  const checkboxStyling = {
    color: showError ? `var(--red)` : `var(--purple)`,
    '&.Mui-checked': {
      color: `var(--purple)`,
    },
  };

  return (
    <FormContainer onBack={onBack}>
      <StyledAcceptTerms>
        <StyledPrivacyPolicy />

        <CheckboxContainer>
          <Checkbox
            sx={checkboxStyling}
            checked={acceptCheckboxIsChecked}
            onChange={() => setAcceptCheckboxIsChecked(!acceptCheckboxIsChecked)}
            aria-labelledby="accept-terms-tos-link"
          />
          <Typography id="accept-terms-tos-link">
            {/* TODO: add screen reader-only text that says "Opens in a new tab" */}I have read and agree to the{' '}
            <DocumentLink href="/tos-provider" target="_blank" rel="noopener noreferrer">
              all of the above terms
            </DocumentLink>
          </Typography>
        </CheckboxContainer>

        {/* This div stops gap from being applied to the button and its error message */}
        <div>
          <SubmitButton onClick={handleSubmit} isLoading={isSubmitting}>
            Agree and sign up
          </SubmitButton>

          <ErrorMessage visible={showError}>Please agree to the terms in order to continue.</ErrorMessage>
        </div>
      </StyledAcceptTerms>
    </FormContainer>
  );
};
